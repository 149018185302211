import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEllipsisVertical,
  faPlus,
  faTimes,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { BASEURL } from "./Baseurl/Baseurl";



export function Header2() {
  const [showModal, setShowModal] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
   
    setCategoryTitle("");
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleTitleChange = (event) => {
    setCategoryTitle(event.target.value);
  };

  const handleAddCategory = () => {

    console.log("Adding category with title:", categoryTitle);

    handleCloseModal();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <Navbar
              bg="light"
              expand="lg"
              style={{
                boxShadow: "0px 2px 5px rgba(0.3, 0, 0, 0.3)",
                backgroundColor: "#DEDEDE",
                borderBottom: "1.5px solid #000000",
                borderTop: "2px solid #D4D4D4",
              }}
            >
              <Container fluid>
                <div className="col-lg-12 row">
                  <div className="col-lg-9 d-flex">
                   <Link to="/"> <FontAwesomeIcon
                      className="homevou mt-3"
                      icon={faHome}
                    /></Link>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                    <marquee behavior="scroll" direction="left" scrollamount="7">
  <Nav className="me-auto ms-5 my-2 my-lg-0 font" style={{ maxHeight: "100px" }} navbarScroll>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Dining
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Electronics
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Fashion
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Wellness
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Gifting
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Home Needs
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      E-Commerce
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Entertainment
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Travel
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      MUA
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
     Salon & spa
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
    Decors
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
      Band
    </Nav.Link>
    <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
    Event Planners
    </Nav.Link>
  </Nav>
</marquee>

                    
                 
                    </Navbar.Collapse>
                  </div>
                  <div className="col-lg-3 d-flex justify-content-end">
                    <FontAwesomeIcon
                      className="text-dark  mt-3"
                      icon={faEllipsisVertical}
                    />
                    <h5 className="mt-1 ml-2 gifticon2"> |</h5>
                    <button
                      className="stavou ml-5 d-flex"
                      onClick={handleOpenModal}
                    >
                      <FontAwesomeIcon
                        className="p-3 ml-2 gifticon"
                        icon={faGift}
                      />
                      <h6 className="jik mt-2 ml-3">

                      <Link to="/Custom" style={{ textDecoration: 'none', color: 'inherit' }}>

                        <u
                          style={{
                            marginTop: "4px",
                            display: "inline-block",
                          }}
                        >
                         Custom Template
                        </u>
                        </Link>
                      </h6>
                    </button>
                  </div>
                </div>
              </Container>
            </Navbar>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>

      {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton={false}>
        <Modal.Title><h5>Add New Category</h5></Modal.Title>
          <FontAwesomeIcon icon={faTimes} onClick={handleCloseModal} />

       </Modal.Header>
       <Modal.Body>
          <center>
          <label>Title : </label>
           <input
             type="text"
            value={categoryTitle}
           onChange={handleTitleChange}
            placeholder="Enter category title"
          />
           </center>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleAddCategory}>
            OK
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

// import React, { useState } from "react";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faHome,
//   faEllipsisVertical,
//   faPlus,
// } from "@fortawesome/free-solid-svg-icons";
// import Modal from "react-bootstrap/Modal";

// export function Header2() {
//   const [showModal, setShowModal] = useState(false);
//   const [categoryTitle, setCategoryTitle] = useState("");

//   const handleCloseModal = () => {
//     setShowModal(false);
//     // Clear the category title input field
//     setCategoryTitle("");
//   };

//   const handleOpenModal = () => {
//     setShowModal(true);
//   };

//   const handleTitleChange = (event) => {
//     setCategoryTitle(event.target.value);
//   };

//   const handleAddCategory = () => {
//     // Implement your logic to add the category
//     console.log("Adding category with title:", categoryTitle);
//     // Close the modal
//     handleCloseModal();
//   };

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-lg-1"></div>
//           <div className="col-lg-10">
//             <Navbar
//               bg="light"
//               expand="lg"
//               style={{
//                 boxShadow: "0px 2px 5px rgba(0.3, 0, 0, 0.3)",
//                 backgroundColor: "#DEDEDE",
//                 borderBottom: "1.5px solid #000000",
//                 borderTop: "2px solid #D4D4D4",
//               }}
//             >
//               <Container fluid>
//                 <div className="col-lg-12 row">
//                   <div className="col-lg-9 d-flex">
//                     <FontAwesomeIcon
//                       className="homevou mt-3"
//                       icon={faHome}
//                     />
//                     <Navbar.Toggle aria-controls="navbarScroll" />
//                     <Navbar.Collapse id="navbarScroll">
//                       <Nav
//                         className="me-auto ms-5 my-2 my-lg-0 font"
//                         style={{ maxHeight: "100px" }}
//                         navbarScroll
//                       >
//                         {/* Your Nav links */}
//                       </Nav>
//                     </Navbar.Collapse>
//                   </div>
//                   <div className="col-lg-3 d-flex justify-content-end">
//                     <FontAwesomeIcon
//                       className="text-dark  mt-3"
//                       icon={faEllipsisVertical}
//                     />
//                     <h5 className="mt-1 ml-2 gifticon2"> |</h5>
//                     <button
//                       className="stavou ml-5 d-flex"
//                       onClick={handleOpenModal}
//                     >
//                       <FontAwesomeIcon
//                         className="p-3 ml-2 gifticon"
//                         icon={faPlus}
//                       />
//                       <h6 className="jik mt-2 ml-3">
//                         <u
//                           style={{
//                             marginTop: "4px",
//                             display: "inline-block",
//                           }}
//                         >
//                           Add New Category
//                         </u>
//                       </h6>
//                     </button>
//                   </div>
//                 </div>
//               </Container>
//             </Navbar>
//           </div>
//           <div className="col-lg-1"></div>
//         </div>
//       </div>

//       {/* Modal for adding new category */}
//       <Modal show={showModal} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add New Category</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <center>
//           <label>Title : </label>
//           <input
//             type="text"
//             value={categoryTitle}
//             onChange={handleTitleChange}
//             placeholder="Enter category title"
//           />
//           </center>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="btn btn-primary" onClick={handleAddCategory}>
//             OK
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// import React from "react";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faHome,
//   faEllipsisVertical,
//   faGifts,
//   faPlus,
// } from "@fortawesome/free-solid-svg-icons";
// import "./CSS_FILES/main.css";
// import { useState } from "react";

// export function Header2() {
//   const [open, setOpen] = React.useState(false);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-lg-1" ></div>
//           <div className="col-lg-10">
//             <Navbar
//               bg="light"
//               expand="lg"
//               style={{
//                 boxShadow: "0px 2px 5px rgba(0.3, 0, 0, 0.3)",
//                 backgroundColor: "#DEDEDE",
//                 borderBottom: "1.5px solid #000000",
//                 borderTop: "2px solid #D4D4D4",
//               }}
//             >
//               <Container fluid>
//                 <div className=" col-lg-12 row">
//                   <div className="col-lg-9 d-flex">
//                     <FontAwesomeIcon className="homevou mt-3" icon={faHome} />
//                     <Navbar.Toggle aria-controls="navbarScroll" />
//                     <Navbar.Collapse id="navbarScroll">
//                       <Nav
//                         className="me-auto ms-5 my-2 my-lg-0 font"
//                         style={{ maxHeight: "100px" }}
//                         navbarScroll
//                       >
//                         <Nav.Link
//                           href="./Dashboard"
//                           className="nav-link text-dark mt-2 dash-text"
//                         >
//                           Dining
//                           </Nav.Link>
              
    
//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//           Electronics
//               </Nav.Link>

//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//         Fashion
//               </Nav.Link>
//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//           Wellness
//               </Nav.Link>
//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//          Gifting
//               </Nav.Link>
//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//           Home Needs
//               </Nav.Link>
//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//            E-Commerce
//               </Nav.Link>
//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//         Entertainment
//               </Nav.Link>
//               <Nav.Link
//                 href="./Attendance"
//                 className="nav-link text-dark mt-2 dash-text"
//               >
               
//          Travel
//               </Nav.Link>

//                       </Nav>
//                     </Navbar.Collapse>
//                   </div>
//                   <div className="col-lg-3 d-flex justify-content-end">  
//           <FontAwesomeIcon className="text-dark  mt-3" icon={faEllipsisVertical} />
//            <h5 className="mt-1 ml-2 gifticon2"> |</h5>
//            <button className="stavou ml-5 d-flex">
//            <FontAwesomeIcon className="p-3 ml-2 gifticon" icon={faPlus} />
//   <h6 className="jik mt-2 ml-3">
//     <u style={{ marginTop: "4px", display: "inline-block" }}>Add New Category</u>
//   </h6>
// </button>
// </div>
//                 </div>
//               </Container>
//             </Navbar>
//           </div>
//           <div className="col-lg-1"></div>
//         </div>
//       </div>
//     </>
//   );
// }
