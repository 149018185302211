

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import './Dining.css';
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faHome,
//   faEllipsisVertical,
//   faPlus,
//   faTimes,
//   faGift,

// } from "@fortawesome/free-solid-svg-icons";
// import { faArrowRight} from "@fortawesome/free-solid-svg-icons";
// import Modal from "react-bootstrap/Modal";
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// export function Category() {
// const cid=localStorage.getItem("selectedCategoryId")




//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-lg-1"></div>
//           <div className="col-lg-10">
//             <div className="col-lg-12 row">
//               <div className="col-lg-2 menudiv">
       
//                 <ul className="category-list mt-3">
//                 <li><b>Categories</b></li>
//                 <hr></hr>
//                   <li><b>Event Planner</b></li>
//                   <li><b>Saloon & spa</b></li>
//                   <li><b>Entertainment</b></li>
//                   <li><b>Travel</b></li>
//                   <li><b>Wellness</b></li>
//                   <li><b>Gifting</b></li>
//                   <li><b>Home Needs</b></li>
//                   <li><b>Fashion</b></li>
//                   <li><b>Electronics</b></li>
//                   <li><b>Decors</b></li>
//                   <li><b>Real Estate</b></li>
//                   <li><b>Event Planner</b></li>
//                   <li><b>Saloon & spa</b></li>
//                   <li><b>Entertainment</b></li>
//                   <li><b>Travel</b></li>
//                   <li><b>Wellness</b></li>
//                   <li><b>Gifting</b></li>
//                   <li><b>Home Needs</b></li>
//                   <li><b>Fashion</b></li>
//                   <li><b>Electronics</b></li>
//                   <li><b>Decors</b></li>
//                   <li><b>Real Estate</b></li>
       
//                 </ul>
//               </div>

//               <div className="col-lg-10 catdiv">
//               <h6 className="mt-4"><b >Sub Categories</b> </h6>
            
//               {/* <b >Sub Categories</b>  */}
//               <div className="col-lg-12  justify-content-between d-flex">
//               <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxI-vkGuKYVzOCfQWef444tZvvC1mCqUuNAw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>MUA</center></Card.Title>
  
//     </Card.Body>
//     <Link to="/BgImg"><Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//           <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//         </Card.Footer></Link>
//    </Card>  </div>



//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFpC7IAupmKd2E73xwe2ZiMqFO3zHBZ7RDDw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Parlour</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>


//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLcbroQi5PMbvezdnLvqdny2UeHYhD7q1k0w&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Stage Decors</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>
//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXhuSk4e2gUmP3XlUnGYqKJJOVboBrTsWjWw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Photography</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>
//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREo0DwANiXFS_tp_hTlZq_7rsZ0Tq_HG41Mw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Spa & Massage</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>



//               </div>


//               <div className="col-lg-12  justify-content-between d-flex">
//               <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxI-vkGuKYVzOCfQWef444tZvvC1mCqUuNAw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>MUA</center></Card.Title>
  
//     </Card.Body>
//     <Link to="/BgImg"><Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//           <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//         </Card.Footer></Link>
//    </Card>  </div>



//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFpC7IAupmKd2E73xwe2ZiMqFO3zHBZ7RDDw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Parlour</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>


//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLcbroQi5PMbvezdnLvqdny2UeHYhD7q1k0w&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Stage Decors</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>
//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXhuSk4e2gUmP3XlUnGYqKJJOVboBrTsWjWw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Photography</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>
//    <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREo0DwANiXFS_tp_hTlZq_7rsZ0Tq_HG41Mw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>Spa & Massage</center></Card.Title>
  
//     </Card.Body>
//      <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//    </Card>  </div>



//               </div>




//               <div className="col-lg-12 d-flex justify-content-between align-items-left">

//               <div className="col-lg-2 newdivv mt-5">
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxI-vkGuKYVzOCfQWef444tZvvC1mCqUuNAw&s"}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>MUA</center></Card.Title>
  
//     </Card.Body>
//     <Link to="/BgImg"><Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//           <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//         </Card.Footer></Link>
//    </Card>  </div>











//               </div>
//             </div>
//           </div>


          
//           <div className="col-lg-1"></div>
//         </div>
//       </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './Dining.css';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEllipsisVertical,
  faPlus,
  faTimes,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { BASEURL } from "./Baseurl/Baseurl";
export function Category() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [cid, setCid] = useState(localStorage.getItem("selectedCategoryId") || null);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState("");

  useEffect(() => {
    fetch(`${BASEURL}categories`)
      .then(response => response.json())
      .then(data => {
        setCategories(data.Response.Result);
      })
      .catch(error => console.error("Error fetching categories:", error));
  }, []);

  useEffect(() => {
    if (cid) {
      fetch(`${BASEURL}sub_categories?cid=${cid}`)
        .then(response => response.json())
        .then(data => {
          setSubcategories(data.Response.Result);
        })
        .catch(error => console.error("Error fetching categories:", error));
    }
  }, [cid]);

  const handleLiClick = (categoryId, categoryTitle) => {
    localStorage.setItem('selectedCategoryId', categoryId);
    setCid(categoryId);
    setSelectedCategoryTitle(categoryTitle);
  }

  function chunkArray(arr, chunkSize) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  }

  const handleButtonClick = (subcategoryId) => {
    localStorage.setItem('selectedsubCategoryId', subcategoryId);
window.location.href="/BgImg"
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="col-lg-12 row">
              <div className="col-lg-2 menudiv">
                <ul className="category-list mt-3">
                  <li><b>Categories</b></li>
                  <hr />
                  {categories.map(category => (
                    <li 
                      key={category.sb_category_id}
                      onClick={() => handleLiClick(category.sb_category_id, category.sb_category_title)}
                      style={{ cursor: cid === category.sb_category_id ? 'pointer' : 'default' }}
                    >
                      <b>{category.sb_category_title}</b>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-10 catdiv">
                <h6 className="mt-4"><b>{selectedCategoryTitle || "Sub Categories"}</b></h6>
                
        <div className="col-lg-12 col-12">
  {chunkArray(subcategories, 6).map((chunk, index) => (
    <div className="row" key={index}>
      {chunk.map((subcategory) => (
        <div className="col-lg-2 col-12 newdivv mt-5 ms-3" key={subcategory.sb_category_id}>
          <Card style={{ height: '90px' }}>
            {/* <Card.Img variant="top"  src={subcategory.sb_category_icon}  className="img-covere"/> */}
            <Card.Img variant="top" src={subcategory.sb_category_icon} className="rounded" style={{ width: '100%', height: '100px', objectFit: 'cover' }} />
            <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Card.Title><center>{subcategory.sb_category_title}</center></Card.Title>
            </Card.Body>

              <Card.Footer className="mt-4" style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
                <Button variant="outline-primary" style={{ width: '100%' }} onClick={() => handleButtonClick(subcategory.sb_category_id)}>
                  <h6 className="jik mt-1">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </h6>
                </Button>
              </Card.Footer>
            
          </Card>
           {/* <Card style={{ height: 'auto', width: 'auto', padding: '5%', textAlign: 'center' }}>
                                                                <Card.Img variant="top" src={subcategory.sb_category_icon} className="rounded" style={{ width: '100%', height: '100px', objectFit: 'cover' }} />
                                                                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                    <Card.Title style={{ fontSize: '14px' }}><center>{subcategory.sb_category_title}</center></Card.Title>
                                                                </Card.Body>
                                                                <Button variant="outline-primary" style={{ width: '100%' }} onClick={() => handleButtonClick(subcategory.sb_category_id)}>
                                                                    <h6 className="jik mt-1">
                                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                                    </h6>
                                                                </Button>
                                                            </Card> */}
        </div>
      ))}
    </div>
  ))}
</div>



              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </>
  );
}




// import React, { useState,useEffect } from "react";
// import { Link } from "react-router-dom";
// import './Dining.css';
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faHome,
//   faEllipsisVertical,
//   faPlus,
//   faTimes,
//   faGift,

// } from "@fortawesome/free-solid-svg-icons";
// import { faArrowRight} from "@fortawesome/free-solid-svg-icons";
// import Modal from "react-bootstrap/Modal";
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// export function Category() {


// const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     fetch("http://localhost:3009/categories")
//       .then(response => response.json())
//       .then(data => {
//         setCategories(data.Response.Result);
//       })
//       .catch(error => console.error("Error fetching categories:", error));
//   }, []);


//   const [subcategories, setSubcategories] = useState([]);
//   const [cid, setCid] = useState(localStorage.getItem("selectedCategoryId") || null);
  
//   useEffect(() => {
//     if (cid) {
//       fetch(`http://localhost:3009/sub_categories?cid=${cid}`)
//         .then(response => response.json())
//         .then(data => {
//           setSubcategories(data.Response.Result);
//         })
//         .catch(error => console.error("Error fetching categories:", error));
//     }
//   }, [cid]);
  
//   // const handleLiClick = (categoryId) => {
//   //   localStorage.setItem('selectedCategoryId', categoryId);
//   //   setCid(categoryId);
//   // }
//   const handleLiClick = (categoryId) => {
//     console.log('Clicked category ID:', categoryId);
//     localStorage.setItem('selectedCategoryId', categoryId);
//     setCid(categoryId);
//   }
  
  
//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-lg-1"></div>
//           <div className="col-lg-10">
//             <div className="col-lg-12 row">
//               <div className="col-lg-2 menudiv">
//               <ul className="category-list mt-3">
//   <li><b>Categories</b></li>
//   <hr />
 
//   {categories.map(category => (
//   <li 
//     key={category.sb_category_id}
//     onClick={() => handleLiClick(category.sb_category_id)}
//     style={{ cursor: 'pointer' }}
//   >
//     <b>{category.sb_category_title}</b>
//   </li>
// ))}





// </ul>

//               </div>

//               <div className="col-lg-10 catdiv">
//               <h6 className="mt-4"><b >Sub Categories</b> </h6>
            
            
//               <div className="col-lg-12  justify-content-left d-flex">

//               {subcategories.map(subcategory => (  
                 
//               <div className="col-lg-2 newdivv mt-5 ms-3" key={subcategory.sb_category_id}>
//     <Card style={{ height: '90px' }}>
//     <Card.Img variant="top"  src={subcategory.sb_category_icon}  className="img-covere"/>
//      <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//      <Card.Title><center>{subcategory.sb_category_title}</center></Card.Title>
  
//     </Card.Body>
//     <Link to="/BgImg"><Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//           <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//         </Card.Footer></Link>
//    </Card>  </div>))}







//               </div>







   
//             </div>
//           </div>


          
//           <div className="col-lg-1"></div>
//         </div>
//       </div>
//       </div>
//     </>
//   );
// }
