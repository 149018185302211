
import React, { useState, useEffect } from "react";
import { Container, Navbar, Form, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSignOut, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import img from "./images/logo2.jpeg";
import { decryptPiDataNew } from './crypt1';
import { InputOTP } from 'antd-input-otp';
import img1 from "./images/emplogo.jpeg";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { BASEURL } from "./Baseurl/Baseurl";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Header() {



//   const handleLogout = () => {

//     // const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
 
//     if (window.confirm("Are you sure you want to log out?")) {
//         const itemsToRemove = [
//             'handleid',
//             'selectedsubCategoryId',
//             'selectedTemplate',
//             'handleimage',
//             'userId',
//             'userName',
//             'role',
//             'profile',
//             'mobile',
//             'selectedCategoryId'
//         ];

        
//         itemsToRemove.forEach(item => localStorage.removeItem(item));

//     }
// };
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success');

const handleSnackbarClose = () => setSnackbarOpen(false);

  const [showModal, setShowModal] = useState(false);
  const [mobileNo, setMobileNo] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpTimer, setOtpTimer] = useState(30);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [serverOtp, setServerOtp] = useState('');

  useEffect(() => {
    let timer;
    if (otpSent && otpTimer > 0) {
      timer = setTimeout(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [otpSent, otpTimer]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setOtpSent(false);
    setOtpTimer(30);
    setOtp('');
    setMobileNo('');
  };

  const handleMobileNoChange = (event) => setMobileNo(event.target.value);
  const handleOtpChange = (value) => {
    const otpValue = value.join('');
    setOtp(otpValue);
  };

  const handleLoginSignup = async () => {
    try {
      const response = await axios.post(`${BASEURL}login_otp`, { phone_num: mobileNo });
      const data = response.data;
      if (data.Response.Success === 1) {
        setServerOtp(data.Response.Result);
        setOtpSent(true);
        setOtpTimer(30);
        setSnackbarSeverity('success');
        setSnackbarMessage('OTP sent successfully!');
        setSnackbarOpen(true);
      } else {
        // alert(data.Response.Message);
        setSnackbarSeverity('error');
        setSnackbarMessage(data.Response.Message);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      // alert('Failed to send OTP. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to send OTP. Please try again.');
      setSnackbarOpen(true);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      if (!serverOtp) {
        // alert('Server OTP not received. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarMessage('Server OTP not received. Please try again.');
        setSnackbarOpen(true);
        return;
      }

      const decryptedOtp = decryptPiDataNew(serverOtp);

      if (otp == decryptedOtp) {
        // alert('OTP verified successfully!');
        setSnackbarSeverity('success');
        setSnackbarMessage('OTP verified successfully!');
        setSnackbarOpen(true);
        await fetchUserData(mobileNo); 
        handleCloseModal();
      } else {
        console.log(decryptedOtp, otp);
        setSnackbarSeverity('error');
        setSnackbarMessage('Invalid OTP. Please try again.');
        setSnackbarOpen(true);
        // alert('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error decrypting OTP:', error);
      // alert('Failed to verify OTP. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to verify OTP. Please try again.');
      setSnackbarOpen(true);
    }
  };
  const [userData, setUserData] = useState({
    userId: '',
    userName: '',
    role: '',
    profile: '',
    mobile: ''
  });

  useEffect(() => {
    const mobile = localStorage.getItem('mobile');
    if (mobile) {
      fetchUserData(mobile);
    }
  }, []);

  const fetchUserData = async (mobile) => {
    try {
      const response = await axios.get(`${BASEURL}loginuser/${mobile}`);
      const data = response.data;
      if (data.Response.Success === "1") {
        const user = data.Response.Result;
        setUserData({
          userId: user.sb_users_id,
          userName: user.sb_users_name,
          role: user.sb_users_role,
          profile: user.sb_users_profile_img,
          mobile: user.sb_users_mobile
        });
      } else {
        console.error('Failed to retrieve user data. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('userId', userData.userId);
      localStorage.setItem('userName', userData.userName);
      localStorage.setItem('role', userData.role);
      localStorage.setItem('profile', userData.profile);
      localStorage.setItem('mobile', userData.mobile);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userData]);




  const [showModal1, setShowModal1] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [profile, setProfile] = useState(null);
  const [mobile, setMobile] = useState('');

  const handleCloseModal1 = () => {
    setShowModal1(false);
    setName('');
    setEmail('');
    setProfile('');
    setMobile('');
  };

  const handleOpenModal1 = () => {
    setShowModal1(true);
    // handleShowModal(true);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleProfileChange = (event) => {
    setProfile(event.target.files[0]);
  };

  const handleAddCategory = async () => {
    const formData = new FormData();
    formData.append('sb_users_name', name);
    formData.append('sb_users_email', email);
    formData.append('sb_users_mobile', mobile);
    formData.append('image', profile);

    try {
      const response = await axios.post(`${BASEURL}api/users`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.Response.Success === "1") {
        // alert('User created successfully!');
        setSnackbarSeverity('success');
        setSnackbarMessage('User created successfully!');
        setSnackbarOpen(true);
        handleCloseModal1();
        handleShowModal()
      } else {
        // alert(response.data.Response.Result);
        setSnackbarSeverity('error');
        setSnackbarMessage(response.data.Response.Result);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error creating user:', error);
      // alert('Failed to create user. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to create user. Please try again.');
      setSnackbarOpen(true);
    }
  };
const pro=localStorage.getItem('profile');
console.log("pro")











  const [bankAccountName, setBankAccountName] = useState('');
  const [bankBranchName, setBankBranchName] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [upiId, setUpiId] = useState('');
  const [verified, setVerified] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [adminStatus, setAdminStatus] = useState('');
  const [panImg, setPanImg] = useState(null);
  const [passbookImg, setPassbookImg] = useState(null);





  const handleOpenProfileModal = async () => {
    try {
      const mobile = localStorage.getItem('mobile');
      const response = await axios.get(`${BASEURL}loginuser/${mobile}`);
      const data = response.data;
      if (data.Response.Success === "1") {
        const user = data.Response.Result;
        setName(user.sb_users_name);
        setEmail(user.sb_users_email);
        setMobile(user.sb_users_mobile);
        setBankAccountName(user.sb_users_bank_account_name || '');
        setBankBranchName(user.sb_users_bank_branch_name || '');
        setIfscCode(user.sb_users_bank_ifsc_code || '');
        setAccountNumber(user.sb_users_bank_account_number || '');
        setUpiId(user.sb_users_upi_id || '');
        setVerified(user.sb_users_verified || '');
        setRole(user.sb_users_role || '');
        setStatus(user.sb_users_status || '');
        setAdminStatus(user.sb_users_admin_status || '');
        setProfile(user.sb_users_profile_img || '');
        setPanImg(user.sb_users_pan_img || '');
        setPassbookImg(user.sb_users_passbook_img || '');



        const profileImg = user.sb_users_profile_img || '';
        const panImage = user.sb_users_pan_img || '';
        const passbookImage = user.sb_users_passbook_img || '';

        setProfile(profileImg);
        setPanImg(panImage);
        setPassbookImg(passbookImage);

        setPreview(profileImg);
        setPreview1(panImage);
        setPreview2(passbookImage);



        setShowProfileModal(true);
   
      } else {
        // alert('Failed to retrieve user data.');
        setSnackbarSeverity('error');
        setSnackbarMessage('Failed to retrieve user data.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Login Your Register Account First!');
      setSnackbarOpen(true);
      // alert('Login Your Register Account First!');
    }
  };





const [preview, setPreview] = useState(localStorage.getItem('profile') || '');
const [preview1, setPreview1] = useState('');
const [preview2, setPreview2] = useState('');

useEffect(() => {
  if (preview) {
    console.log("preview", preview);
  }
}, [preview]);

useEffect(() => {
  if (preview1) {
    console.log("preview1", preview1);
  }
}, [preview1]);

useEffect(() => {
  if (preview2) {
    console.log("preview2", preview2);
  }
}, [preview2]);

const handleImageChange = (event) => {
  const file = event.target.files[0];
  setProfile(file);

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  }
};

const handlePanImgChange = (event) => {
  const file = event.target.files[0];
  setPanImg(file);

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  }
};

const handlePassbookImgChange = (event) => {
  const file = event.target.files[0];
  setPassbookImg(file);

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  }
};






  const handleUpdateUser = async () => {
    const userId = localStorage.getItem('userId');
    const formData = new FormData();
    formData.append('sb_users_name', name);
    formData.append('sb_users_email', email);
    formData.append('sb_users_mobile', mobile);
    formData.append('sb_users_bank_account_name', bankAccountName);
    formData.append('sb_users_bank_branch_name', bankBranchName);
    formData.append('sb_users_bank_ifsc_code', ifscCode);
    formData.append('sb_users_bank_account_number', accountNumber);
    formData.append('sb_users_upi_id', upiId);
    formData.append('sb_users_verified', verified);
    formData.append('sb_users_role', role);
    formData.append('sb_users_status', status);
    formData.append('sb_users_admin_status', adminStatus);

  if (profile) formData.append('sb_users_profile_img', profile);
    if (panImg) formData.append('sb_users_pan_img', panImg);
    if (passbookImg) formData.append('sb_users_passbook_img', passbookImg);

    try {
      const response = await axios.post(`${BASEURL}update_user/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.Response.Success === "1") {
        // alert('User updated successfully!');
        setSnackbarSeverity('success');
        setSnackbarMessage('User updated successfully!');
        setSnackbarOpen(true);
        handleCloseProfileModal();
        window.location.reload();
    
      } else {
        // alert(response.data.Response.Result);
        setSnackbarSeverity('error');
        setSnackbarMessage(response.data.Response.Result);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to update user. Please try again.');
      setSnackbarOpen(true);
      // alert('Failed to update user. Please try again.');
    }
  };

  const handleCloseProfileModal = () => {
    setShowProfileModal(false);
    setName('');
    setEmail('');
    setMobile('');
    setProfile('');
    setBankAccountName('');
    setBankBranchName('');
    setIfscCode('');
    setAccountNumber('');
    setUpiId('');
    setVerified('');
    setRole('');
    setStatus('');
    setAdminStatus('');
    setPanImg('');
    setPassbookImg('');
  };


 const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const handleLogoutDialogOpen = () => setLogoutDialogOpen(true);
  const handleLogoutDialogClose = () => setLogoutDialogOpen(false);

  const handleLogout = () => {
    const itemsToRemove = [
      'handleid',
      'selectedsubCategoryId',
      'selectedTemplate',
      'handleimage',
      'userId',
      'userName',
      'role',
      'profile',
      'mobile',
      'selectedCategoryId'
    ];
    itemsToRemove.forEach(item => localStorage.removeItem(item));
    handleLogoutDialogClose();
  };


  return (
    <>
      <div className="col-lg-12 row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <Navbar bg="light" expand="lg">
            <Container fluid>
              <div className="col-lg-12 row">
                <div className="col-lg-2 d-flex">
                  {/* <FontAwesomeIcon className="text-dark vouicon mb-3" icon={faBars} /> */}
                  <Navbar.Brand href="#">
                    <img
                      alt=""
                      src={img}
                      width="180"
                      height="40"
                      className="justify-content-start mt-1"
                      />
                    </Navbar.Brand>
                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-4 mt-4">
                    {/* <Form className="d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Search category"
                        className="me-2"
                        aria-label="Search"
                      />
                      <Button variant="outline-dark">Search</Button>
                    </Form> */}
{/*  */}


                  </div>
                  <div className="col-lg-4 d-flex justify-content-end">
                    <h6 className="mt-4 jik text-primary" onClick={handleShowModal}>Login /</h6>
                    <h6 className="mt-4 jik text-primary" onClick={handleOpenModal1}> Signup</h6>

                    <img
      alt="Description of the image"
      src={pro ? pro : img1}  
      width="50"
      height="50"
      className="ml-2 rounded-circle mt-1"
      onClick={handleOpenProfileModal}

    />  <button onClick={handleLogoutDialogOpen} className="bg-white btn-outline-white" ><FontAwesomeIcon className="text-secondary vouicon mb-3" icon={faSignOut} /></button>



                  </div>
                </div>
              </Container>
            </Navbar>
          </div>
          <div className="col-lg-1"></div>
        </div>
  
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header>
            <Modal.Title className="text-dark d-flex">
              <img src={img} alt="Logo" className="mt-3" width="230" height="40" />
              <h5 className="mt-3 ml-5"> Hello there! Ready to dive in?</h5>
            </Modal.Title>
            <button className="bg-danger border-danger close-button" onClick={handleCloseModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="col-lg-12 mt-2 d-flex">
                <div className="col-lg-1"></div>
                <div className="col-lg-10 mt-2">
                  <div className="input-group">
                    <span className="input-group-text">+91</span>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter your mobile number"
                      value={mobileNo}
                      onChange={handleMobileNoChange}
                    />
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <hr />
              </div>
            </div>
            <center>
              {otpSent ? (
                <>
                  <div className="otp-sent-info">
                    <p>Please enter the OTP sent to your mobile number</p>
                    <div className="otp-input">
                      <InputOTP
                        length={4}
                        otpType="number"
                        autoFocus
                        value={otp.split('')}
                        onChange={handleOtpChange}
                      />
                    </div>
                    <div className="otp-timer mt-3">
                      <p>{otpTimer} sec</p>
                    </div>
                    <Button variant="primary mt-1" onClick={handleVerifyOtp}>Verify</Button>
                  </div>
                </>
              ) : (
                <Button variant="primary LOG mt-3" onClick={handleLoginSignup}>Login with OTP</Button>
              )}
              <div className="col-lg-12 mt-3 d-flex">
                <div className="col-lg-4">
                  <p><hr /></p>
                </div>
                <div className="col-lg-4">
                  <Button variant="" onClick={handleLoginSignup}>or login using</Button>
                </div>
                <div className="col-lg-4">
                  <p><hr /></p>
                </div>
              </div>
              <Button variant="LOG" className="huu btn-outline-secondary mt-3" onClick={handleLoginSignup}>Login with email</Button>
            </center>
            <p className="text-secondary">skip</p>
          </Modal.Body>
        </Modal>
  
        <Modal show={showModal1} onHide={handleCloseModal1}>
          <Modal.Header>
            <Modal.Title className="text-dark">Connect, grow, and succeed. Sign up Now!</Modal.Title>
            <button className="bg-danger border-danger close-button" onClick={handleCloseModal1}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label htmlFor="accountHolder">Name *:</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter user name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="branchName">Email *:</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter valid email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="branchName">Mobile No *:</label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  value={mobile}
                  onChange={handleMobileChange}
                  placeholder="Enter active mobile No"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="panImg">Profile *:</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="profile"
                  onChange={handleProfileChange}
                  required
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={handleAddCategory}>
              OK
            </button>
          </Modal.Footer>
        </Modal>


        {/* <Modal show={showProfileModal} onHide={handleCloseProfileModal} centered> */}
        <Modal show={showProfileModal}  onHide={handleCloseProfileModal} >
        <Modal.Header>
          <Modal.Title>Update Your Profile Now!</Modal.Title>
          <button className="bg-danger border-danger close-button" onClick={handleCloseProfileModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={handleNameChange}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailChange}
            />
          </Form.Group>
          <Form.Group controlId="formMobile">
            <Form.Label>Mobile</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter mobile number"
              value={mobile}
              onChange={handleMobileChange}
            />
          </Form.Group>
  
<Form.Group controlId="formProfileImage">
      <Form.Label>Profile Image</Form.Label>
      <Form.Control
        type="file"
        onChange={handleImageChange}
      />
      {preview && (
        <div>
          <img
            alt="Profile Preview"
            src={preview}
            width="100"
            height="100"
            className="justify-content-start mt-2"
          />
        </div>
      )}
    </Form.Group>
          <Form.Group controlId="formBankAccountName">
            <Form.Label>Bank Account Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter bank account name"
              value={bankAccountName}
              onChange={(e) => setBankAccountName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBankBranchName">
            <Form.Label>Bank Branch Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter bank branch name"
              value={bankBranchName}
              onChange={(e) => setBankBranchName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formIfscCode">
            <Form.Label>IFSC Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter IFSC code"
              value={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formAccountNumber">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter account number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formUpiId">
            <Form.Label>UPI ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter UPI ID"
              value={upiId}
              onChange={(e) => setUpiId(e.target.value)}
            />
          </Form.Group>
   
             <Form.Group controlId="formVerified">
      <Form.Label>Verified</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter verified status"
        value={verified == 1 ? 'YES' : 'NO'}
        // onChange={(e) => setVerified(e.target.value)}
      />
      
    </Form.Group>
        
          <Form.Group controlId="formRole">
            <Form.Label>Role</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter role"
              value={role == 1 ? 'USER' : 'CREATOR'}
              // onChange={(e) => setRole(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter status"
              value={status == 1 ? 'ACTIVE' : 'INACTIVE'}
              // onChange={(e) => setStatus(e.target.value)}
            />
          </Form.Group>
  




<Form.Group controlId="formPanImg">
        <Form.Label>PAN Image</Form.Label>
        <Form.Control type="file" onChange={handlePanImgChange} />
        {preview1 && (
          <div>
            <img
              alt="Pan Preview"
              src={preview1}
              width="100"
              height="100"
              className="justify-content-start mt-2"
            />
          </div>
        )}
      </Form.Group>

      <Form.Group controlId="formPassbookImg">
        <Form.Label>Passbook Image</Form.Label>
        <Form.Control type="file" onChange={handlePassbookImgChange} />
        {preview2 && (
          <div>
            <img
              alt="Passbook Preview"
              src={preview2}
              width="100"
              height="100"
              className="justify-content-start mt-2"
            />
          </div>
        )}
      </Form.Group>



    




          <Button className="mt-2" variant="primary" onClick={handleUpdateUser}>
            Update Profile
          </Button>
        </Modal.Body>
      </Modal>

      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
</>

  );
}















