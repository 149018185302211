// import React, { useState } from 'react';
// import './Dining.css';
// import wall from "./images/wall3.avif";
// import { Link } from 'react-router-dom';

// function BgImg() {
//   const [hoverIndex, setHoverIndex] = useState(null);

//   const handleMouseEnter = (index) => {
//     setHoverIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setHoverIndex(null);
//   };

//   const imageCards = [
//     { id: 1, imageUrl: "https://i.pinimg.com/474x/ef/0a/6b/ef0a6b5768d368f9fdd797100c65f211.jpg"},
//     { id: 2, imageUrl: "https://w0.peakpx.com/wallpaper/206/66/HD-wallpaper-wedding-asian-white-women-graphy-brides.jpg" },
//     { id: 3, imageUrl: "https://i.pinimg.com/474x/42/3a/e7/423ae7786ad0ca064ac1d73eb57b93a1.jpg" },
//     { id: 4, imageUrl: "https://i.pinimg.com/474x/03/32/10/03321081a19e3e7d1f6204dc57c6cfbd.jpg" },
//     { id: 5, imageUrl: "https://media.istockphoto.com/id/1336649738/photo/beautiful-traditional-indian-bride-getting-ready-for-her-wedding-day-by-makeup-artist.jpg?s=612x612&w=0&k=20&c=pGYZLd3rYjF4nJ7g8Eeee6QuOHmXmtCRgFuEYYm1IpQ=" },
//     { id: 6, imageUrl: "https://images.pexels.com/photos/2332376/pexels-photo-2332376.jpeg?auto=compress&cs=tinysrgb&w=800" },
//     { id: 7, imageUrl: "https://i.pinimg.com/474x/5b/c4/d8/5bc4d8c8527ecacda3d54a6559b2ca8d.jpg" },
//     { id: 8, imageUrl: "https://i.pinimg.com/474x/87/4a/2c/874a2c7fab215d6e9b03e3929f396847.jpg" },
//     { id: 9, imageUrl: "https://i.pinimg.com/474x/9e/4a/7d/9e4a7dceccf2db4fa47a39cc6aad4554.jpg" },
//     { id: 10, imageUrl: "https://i.pinimg.com/474x/75/a5/c8/75a5c8b67ee9542324ee04cb8ccaefef.jpg" },
// ];

//   return (
//     <div className="container-fluid" style={{ backgroundImage: `url(${wall})`,height: '100%',}}>
//         <div className="col-lg-12">
//  <div className="row">
//  <div className="col-lg-1"></div>
//  <div className="col-lg-10 mt-4">
//       <div className="row justify-content-left">
//         {imageCards.map((card, index) => (
//           <div
//             key={card.id}
//             className="col-lg-4 mb-4"
//             style={{ height: "500px" }}
//           >
//               <Link to={"/Template"}>
//             <div
//               className="image-card position-relative"
//               onMouseEnter={() => handleMouseEnter(index)}
//               onMouseLeave={handleMouseLeave}
//             >
         
//             <img
//                 src={card.imageUrl}
//                 alt={`Image ${card.id}`}
//                 style={{ width: "100%", height: "500px", objectFit: "cover" }}
//               />
//               {hoverIndex === index && (
//                 <div className="overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
//                   <i className="fas fa-arrow-right arrow-icon"></i>
//                 </div>
//               )}
//             </div>
//             </Link>
//           </div>
//         ))}
//         </div>
//       </div>
//       <div className="col-lg-1"></div>
//     </div>
//     </div>
//     </div>
//   );
// }

// export default BgImg;


import React, { useState, useEffect } from 'react';
import './Dining.css';
import wall from "./images/wall3.avif";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { BASEURL } from './Baseurl/Baseurl';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function BgImg() {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [imageCards, setImageCards] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [editTags, setEditTags] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const sid = localStorage.getItem("selectedsubCategoryId");
  const cid = localStorage.getItem("selectedCategoryId");
  const uId = localStorage.getItem("userId");

  useEffect(() => {
    axios.get(`${BASEURL}Bgimg?cid=${cid}&sid=${sid}`)
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data.Response.Success === "1" && Array.isArray(response.data.Response.Result)) {
          setImageCards(response.data.Response.Result);
        } else {
          console.error('No records found or API error');
        }
      })
      .catch(error => {
        console.error('There was an error fetching the data:', error);
      });
  }, [cid, sid]);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const handleRemoveClick = (id) => {
    setSelectedImageId(id);
    setOpenDialog(true);
  };

  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    if (confirm && selectedImageId) {
      axios.post(`${BASEURL}branding/creator_image/remove`, {
        catId: cid,
        ScatId: sid,
        uId: uId,
        imageId: selectedImageId
      })
        .then(response => {
          if (response.data.Response.Success === "1") {
            setImageCards(imageCards.filter(card => card.id !== selectedImageId));
            setSnackbarMessage('Your image has been deleted.');
            setSnackbarSeverity('success');
          } else {
            setSnackbarMessage('You are not authorized to remove this image!');
            setSnackbarSeverity('error');
            console.error('Error removing image:', response.data.Response.Message);
          }
          setSnackbarOpen(true);
        })
        .catch(error => {
          setSnackbarMessage('There was an error removing the image.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          console.error('There was an error removing the image:', error);
        });
    }
  };

  const handleEditClick = (id) => {
    setSelectedImageId(id);
    axios.get(`${BASEURL}branding/creator_image/tagsget?catId=${cid}&ScatId=${sid}&uId=${uId}&imageId=${id}`)
      .then(response => {
        if (response.data.Response.Success === "1") {
          setEditTags(response.data.Response.Result.Tags.join(', '));
        } else {
          setEditTags('');
          setSnackbarMessage(response.data.Response.Message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the tags:', error);
        setSnackbarMessage('There was an error fetching the tags.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
    setOpenEditDialog(true);
  };



  const handleEditDialogClose = async (confirm) => {
    setOpenEditDialog(false);
    if (confirm && selectedImageId) {
      const tagsArray = editTags
        .split(',')
        .map(tag => tag.trim())
        .filter(tag => tag.length > 0);
  
      const requestData = {
        catId: parseInt(cid), 
        ScatId: parseInt(sid),
        uId: parseInt(uId),
        imgId: parseInt(selectedImageId),
        tags: tagsArray
      };
  
      try {
        const response = await axios.post(`${BASEURL}branding/creator_image/update_tags`, requestData);
        console.log('API Response:', response.data);
        if (response.data.Response.Success === "1") {
          setSnackbarMessage('Tags have been updated.');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage('Failed to update tags.');
          setSnackbarSeverity('error');
          console.error('Error updating tags:', response.data.Response.Message);
        }
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('There was an error updating the tags.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.error('There was an error updating the tags:', error);
      }
    }
  };
  
  
  
  
  
  
  
  

  

  const handleId = (id, name, crid) => {
    localStorage.setItem("handleid", id);
    localStorage.setItem("handleimage", name);
    localStorage.setItem("crid", crid);
    window.location.href = "/Template";
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the image:', error);
      setSnackbarMessage('There was an error downloading the image.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="container-fluid" style={{ backgroundImage: `url(${wall})`, height: '100%' }}>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10 mt-4">
            <div className="row justify-content-left">
              {imageCards.length > 0 ? (
                imageCards.map((card, index) => (
                  <div
                    key={card.id}
                    className="col-lg-4 mb-4"
                    style={{ height: "500px" }}
                  >
                    <div
                      className="image-card position-relative"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        src={card.name}
                        alt={`Image ${card.id}`}
                        style={{ width: "100%", height: "500px", objectFit: "cover" }}
                      />
                      {hoverIndex === index && (
                        <div className="overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
                          <i className="fas fa-arrow-right arrow-icon" onClick={() => handleId(card.id, card.name, card.crid)}></i>
                          <div className="remove-icon position-absolute bg-danger" style={{ top: '10px', left: '10px' }}>
                            <i className="fas fa-times" onClick={() => handleRemoveClick(card.id)}></i>
                          </div>
                          <div className="edit-icon position-absolute" style={{ top: '10px', right: '10px', color: 'white' }}>
                            <i className="fas fa-edit" onClick={() => handleEditClick(card.id)}></i>
                          </div>
                          <div className="download-icon position-absolute" style={{ bottom: '10px', left: '10px', color: 'white' }}>
                            <img src={card.creatorProfileImg} alt={card.creatorName} className="rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover', border: '2px solid white' }} />
                            <span className="download-count"> {card.creatorName}</span>
                          </div>
                          <div className="creator-info position-absolute" style={{ bottom: '10px', right: '10px', textAlign: 'right', color: 'white' }}>
                            <i className="fas fa-download" onClick={() => handleDownload(card.name, `Image_${card.id}.jpg`)}></i>
                            <span className="download-count">{card.downloadCount}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No images found</p>
              )}
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this image?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You won't be able to revert this!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Yes, delete it!
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={openEditDialog}
  onClose={() => handleEditDialogClose(false)} // Close dialog without saving
  aria-labelledby="edit-dialog-title"
  aria-describedby="edit-dialog-description"
>
  <DialogTitle id="edit-dialog-title">
    {"Edit Tags"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="edit-dialog-description">
      Update the tags for this image.
    </DialogContentText>
    <TextField
      autoFocus
      margin="dense"
      id="tags"
      label="Tags"
      type="text"
      fullWidth
      variant="outlined"
      value={editTags}
      onChange={(e) => setEditTags(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => handleEditDialogClose(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={() => handleEditDialogClose(true)} color="primary">
      Save
    </Button>
  </DialogActions>
</Dialog>


      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BgImg;



// import React, { useState, useEffect } from 'react';
// import './Dining.css';
// import wall from "./images/wall3.avif";
// import axios from 'axios';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import { BASEURL } from './Baseurl/Baseurl';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// function BgImg() {
//   const [hoverIndex, setHoverIndex] = useState(null);
//   const [imageCards, setImageCards] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedImageId, setSelectedImageId] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [editModalOpen, setEditModalOpen] = useState(false);
//   const [selectedImageTags, setSelectedImageTags] = useState([]);
//   const [selectedImage, setSelectedImage] = useState(null);

//   const sid = localStorage.getItem("selectedsubCategoryId");
//   const cid = localStorage.getItem("selectedCategoryId");
//   const uId = localStorage.getItem("userId");

//   useEffect(() => {
//     axios.get(`${BASEURL}Bgimg?cid=${cid}&sid=${sid}`)
//       .then(response => {
//         console.log('API Response:', response.data); 
//         if (response.data.Response.Success === "1" && Array.isArray(response.data.Response.Result)) {
//           setImageCards(response.data.Response.Result);
//         } else {
//           console.error('No records found or API error');
//         }
//       })
//       .catch(error => {
//         console.error('There was an error fetching the data:', error);
//       });
//   }, [cid, sid]);

//   const handleMouseEnter = (index) => {
//     setHoverIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setHoverIndex(null);
//   };

//   const handleRemoveClick = (id) => {
//     setSelectedImageId(id);
//     setOpenDialog(true);
//   };

//   const handleDialogClose = (confirm) => {
//     setOpenDialog(false);
//     if (confirm && selectedImageId) {
//       axios.post(`${BASEURL}branding/creator_image/remove`, {
//         catId: cid,
//         ScatId: sid,
//         uId: uId,
//         imageId: selectedImageId
//       })
//         .then(response => {
//           if (response.data.Response.Success === "1") {
//             setImageCards(imageCards.filter(card => card.id !== selectedImageId));
//             setSnackbarMessage('Your image has been deleted.');
//             setSnackbarSeverity('success');
//           } else {
//             setSnackbarMessage('You are not authorized to remove this image!');
//             setSnackbarSeverity('error');
//             console.error('Error removing image:', response.data.Response.Message);
//           }
//           setSnackbarOpen(true);
//         })
//         .catch(error => {
//           setSnackbarMessage('There was an error removing the image.');
//           setSnackbarSeverity('error');
//           setSnackbarOpen(true);
//           console.error('There was an error removing the image:', error);
//         });
//     }
//   };

//   const handleId = (id, name, crid) => {
//     setSelectedImage(id);
//     axios.get(`http://localhost:3009/creator-images-tags_get/${id}`)
//       .then(response => {
//         if (response.data.Response.Success === "1") {
//           setSelectedImageTags(response.data.Response.Result.Tags);
//           setEditModalOpen(true);
//         } else {
//           console.error('Error fetching tags:', response.data.Response.Message);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching tags:', error);
//       });
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   const handleDownload = async (url, filename) => {
//     try {
//       const response = await fetch(url);
//       const blob = await response.blob();
//       const link = document.createElement('a');
//       link.href = window.URL.createObjectURL(blob);
//       link.download = filename;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (error) {
//       console.error('Error downloading the image:', error);
//       setSnackbarMessage('There was an error downloading the image.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   const handleEditModalClose = () => {
//     setEditModalOpen(false);
//   };

//   const handleUpdateTags = () => {
//     axios.post(`http://localhost:3009/creator-images-tags/${selectedImage}`, {
//       tags: selectedImageTags
//     })
//       .then(response => {
//         if (response.data.Response.Success === "1") {
//           setSnackbarMessage('Tags updated successfully.');
//           setSnackbarSeverity('success');
//           setSnackbarOpen(true);
        
//         } else {
//           setSnackbarMessage('Error updating tags.');
//           setSnackbarSeverity('error');
//           setSnackbarOpen(true);
//           console.error('Error updating tags:', response.data.Response.Message);
//         }
//       })
//       .catch(error => {
//         setSnackbarMessage('Error updating tags.');
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         console.error('Error updating tags:', error);
//       });
//   };

//   const handleTagChange = (e) => {
//     setSelectedImageTags(e.target.value);
//   };

//   return (
//     <div className="container-fluid" style={{ backgroundImage: `url(${wall})`, height: '100%' }}>
//       <div className="col-lg-12">
//         <div className="row">
//           <div className="col-lg-1"></div>
//           <div className="col-lg-10 mt-4">
//             <div className="row justify-content-left">
//               {imageCards.length > 0 ? (
//                 imageCards.map((card, index) => (
//                   <div
//                     key={card.id}
//                     className="col-lg-4 mb-4"
//                     style={{ height: "500px" }}
//                   >
//                     <div
//                       className="image-card position-relative"
//                       onMouseEnter={() => handleMouseEnter(index)}
//                       onMouseLeave={handleMouseLeave}
//                     >
//                       <img
//                         src={card.name}
//                         alt={`Image ${card.id}`}
//                         style={{ width: "100%", height: "500px", objectFit: "cover" }}
//                       />
//                       {hoverIndex === index && (
//                         <div className="overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
//                           <i className="fas fa-arrow-right arrow-icon" onClick={() => handleId(card.id, card.name, card.crid)}></i>
//                           <div className="remove-icon position-absolute bg-danger" style={{ top: '10px', left: '10px' }}>
//                             <i className="fas fa-times" onClick={() => handleRemoveClick(card.id)}></i>
//                           </div>
//                           <div className="download-icon position-absolute" style={{ bottom: '10px', left: '10px', color: 'white' }}>
//                             <img src={card.creatorProfileImg} alt={card.creatorName} className="rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover', border: '2px solid white' }} />
//                             <span className="download-count"> {card.creatorName}</span>
//                           </div>
//                           <div className="creator-info position-absolute" style={{ bottom: '10px', right: '10px', textAlign: 'right', color: 'white' }}>
//                             <i className="fas fa-download" onClick={() => handleDownload(card.name, `Image_${card.id}.jpg`)}></i>
//                             <span className="download-count">{card.downloadCount}</span>
//                           </div>
                      
//                           <div className="edit-icon position-absolute" style={{ top: '10px', right: '10px' }}>
//                             <i className="fas fa-edit" onClick={() => handleId(card.id, card.name, card.crid)}></i>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <p>No images found</p>
//               )}
//             </div>
//           </div>
//           <div className="col-lg-1"></div>
//         </div>
//       </div>

//       <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
//         <DialogTitle>{"Are you sure you want to remove this image?"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             You won't be able to revert this!
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes, delete it!
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Edit Modal */}
//       <Dialog open={editModalOpen} onClose={handleEditModalClose}>
//         <DialogTitle>Edit Tags</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Edit the tags for the selected image.
//           </DialogContentText>
//           <div className="form-group">
//             <label htmlFor="tags">Tags:</label>
//             <input
//               type="text"
//               className="form-control"
//               id="tags"
//               value={selectedImageTags}
//               onChange={handleTagChange}
//               placeholder="Enter tags, separated by commas"
//             />
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleEditModalClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleUpdateTags} color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
//         <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// }

// export default BgImg;






// import React, { useState, useEffect } from 'react';
// import './Dining.css';
// import wall from "./images/wall3.avif";
// import axios from 'axios';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import { BASEURL } from './Baseurl/Baseurl';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// function BgImg() {
//   const [hoverIndex, setHoverIndex] = useState(null);
//   const [imageCards, setImageCards] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedImageId, setSelectedImageId] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');

//   const sid = localStorage.getItem("selectedsubCategoryId");
//   const cid = localStorage.getItem("selectedCategoryId");
//   const uId = localStorage.getItem("userId");

//   useEffect(() => {
//     axios.get(`${BASEURL}Bgimg?cid=${cid}&sid=${sid}`)
//       .then(response => {
//         console.log('API Response:', response.data); 
//         if (response.data.Response.Success === "1" && Array.isArray(response.data.Response.Result)) {
//           setImageCards(response.data.Response.Result);
//         } else {
//           console.error('No records found or API error');
//         }
//       })
//       .catch(error => {
//         console.error('There was an error fetching the data:', error);
//       });
//   }, [cid, sid]);

//   const handleMouseEnter = (index) => {
//     setHoverIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setHoverIndex(null);
//   };

//   const handleRemoveClick = (id) => {
//     setSelectedImageId(id);
//     setOpenDialog(true);
//   };

//   const handleDialogClose = (confirm) => {
//     setOpenDialog(false);
//     if (confirm && selectedImageId) {
//       axios.post(`${BASEURL}branding/creator_image/remove`, {
//         catId: cid,
//         ScatId: sid,
//         uId: uId,
//         imageId: selectedImageId
//       })
//         .then(response => {
//           if (response.data.Response.Success === "1") {
//             setImageCards(imageCards.filter(card => card.id !== selectedImageId));
//             setSnackbarMessage('Your image has been deleted.');
//             setSnackbarSeverity('success');
//           } else {
//             setSnackbarMessage('You are not authorized to remove this image!');
//             setSnackbarSeverity('error');
//             console.error('Error removing image:', response.data.Response.Message);
//           }
//           setSnackbarOpen(true);
//         })
//         .catch(error => {
//           setSnackbarMessage('There was an error removing the image.');
//           setSnackbarSeverity('error');
//           setSnackbarOpen(true);
//           console.error('There was an error removing the image:', error);
//         });
//     }
//   };

//   const handleId = (id, name, crid) => {
//     localStorage.setItem("handleid", id);
//     localStorage.setItem("handleimage", name);
//     localStorage.setItem("crid", crid);
//     window.location.href = "/Template";
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   const handleDownload = async (url, filename) => {
//     try {
//       const response = await fetch(url);
//       const blob = await response.blob();
//       const link = document.createElement('a');
//       link.href = window.URL.createObjectURL(blob);
//       link.download = filename;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (error) {
//       console.error('Error downloading the image:', error);
//       setSnackbarMessage('There was an error downloading the image.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   return (
//     <div className="container-fluid" style={{ backgroundImage: `url(${wall})`, height: '100%' }}>
//       <div className="col-lg-12">
//         <div className="row">
//           <div className="col-lg-1"></div>
//           <div className="col-lg-10 mt-4">
//             <div className="row justify-content-left">
//               {imageCards.length > 0 ? (
//                 imageCards.map((card, index) => (
//                   <div
//                     key={card.id}
//                     className="col-lg-4 mb-4"
//                     style={{ height: "500px" }}
//                   >
//                     <div
//                       className="image-card position-relative"
//                       onMouseEnter={() => handleMouseEnter(index)}
//                       onMouseLeave={handleMouseLeave}
//                     >
//                       <img
//                         src={card.name}
//                         alt={`Image ${card.id}`}
//                         style={{ width: "100%", height: "500px", objectFit: "cover" }}
//                       />
//                       {hoverIndex === index && (
//                         <div className="overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center">
//                           <i className="fas fa-arrow-right arrow-icon" onClick={() => handleId(card.id, card.name, card.crid)}></i>
//                           <div className="remove-icon position-absolute bg-danger" style={{ top: '10px', left: '10px' }}>
//                             <i className="fas fa-times" onClick={() => handleRemoveClick(card.id)}></i>
//                           </div>
//                           <div className="download-icon position-absolute" style={{ bottom: '10px', left: '10px', color: 'white' }}>
//                             <img src={card.creatorProfileImg} alt={card.creatorName} className="rounded-circle" style={{ width: '40px', height: '40px', objectFit: 'cover', border: '2px solid white' }} />
//                             <span className="download-count"> {card.creatorName}</span>
//                           </div>
//                           <div className="creator-info position-absolute" style={{ bottom: '10px', right: '10px', textAlign: 'right', color: 'white' }}>
//                             <i className="fas fa-download" onClick={() => handleDownload(card.name, `Image_${card.id}.jpg`)}></i>
//                             <span className="download-count">{card.downloadCount}</span>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <p>No images found</p>
//               )}
//             </div>
//           </div>
//           <div className="col-lg-1"></div>
//         </div>
//       </div>

//       <Dialog
//         open={openDialog}
//         onClose={() => handleDialogClose(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Are you sure you want to remove this image?"}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             You won't be able to revert this!
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
//             Yes, delete it!
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
//         <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// }

// export default BgImg;




