// // src/DeleteAccount.js
// import React, { useState } from 'react';
// import Lottie from 'lottie-react';
// // import animationData from './animation.json'; // Make sure to replace with your actual animation file
// import './DeleteAccount.css';
// import animationData from "./json/ti.json";
// const SRpage = () => {
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [otp, setOtp] = useState('');
//     const [otpSent, setOtpSent] = useState(false);
//     const [otpVerified, setOtpVerified] = useState(false);
//     const [showPopup, setShowPopup] = useState(false);

//     const sendOtp = () => {
//         setOtpSent(true);
//     };

//     const verifyOtp = () => {
//         if (otp.length === 4) {
//             setOtpVerified(true);
//         }
//     };

//     const deleteAccount = () => {
//         if (otpVerified) {
//             setShowPopup(true);
//         }
//     };

//     return (
//         <div className="modal">
//             <div className="modal-content">
//                 <h1>Delete Account</h1>
//                 <p>We are sorry to hear this from you,</p>
//                 <p>Deleting your account will remove all of your information from our database. This cannot be undone.</p>
//                 <p>To confirm this verify your mobile number with OTP</p>
//                 <input
//                     type="text"
//                     placeholder="Enter Mobile Number"
//                     value={mobileNumber}
//                     onChange={(e) => setMobileNumber(e.target.value)}
//                 />
//                 <button onClick={sendOtp}>SEND OTP</button>

//                 {otpSent && (
//                     <div className="otp-verification">
//                         <p>Enter OTP Code sent to your mobile number</p>
//                         <div className="otp-input">
//                             {[...Array(4)].map((_, index) => (
//                                 <input
//                                     key={index}
//                                     type="text"
//                                     maxLength="1"
//                                     value={otp[index] || ''}
//                                     onChange={(e) => {
//                                         const newOtp = otp.split('');
//                                         newOtp[index] = e.target.value;
//                                         setOtp(newOtp.join(''));
//                                     }}
//                                 />
//                             ))}
//                         </div>
//                         <button onClick={verifyOtp}>Verify OTP</button>
//                     </div>
//                 )}

//                 {otpVerified && (
//                     <button onClick={deleteAccount} className="delete-btn">Delete your Account</button>
//                 )}

//                 {showPopup && (
//                     <div className="popup">
//                         <p>Your relevant data are removed</p>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };
// export default SRpage;
// src/DeleteAccount.js







// import React, { useState } from 'react';
// import Lottie from 'lottie-react';
// import animationData from "./json/ti.json"; // Your Lottie animation file
// import './DeleteAccount.css';

// const SRpage = () => {
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [otp, setOtp] = useState('');
//     const [otpSent, setOtpSent] = useState(false);
//     const [otpVerified, setOtpVerified] = useState(false);
//     const [showPopup, setShowPopup] = useState(false);

//     const sendOtp = () => {
//         setOtpSent(true);
//     };

//     const verifyOtp = () => {
//         if (otp.length === 4) {
//             setOtpVerified(true);
//         }
//     };

//     const deleteAccount = () => {
//         if (otpVerified) {
//             setShowPopup(true);
//         }
//     };

//     return (
//         <div className="container">
//             <div className="animation">
//                 <Lottie animationData={animationData} loop={true} />
//             </div>
//             <div className="modal">
//                 <div className="modal-content">
//                     <h1>Delete Account</h1>
//                     <p>We are sorry to hear this from you,</p>
//                     <p>Deleting your account will remove all of your information from our database. This cannot be undone.</p>
//                     <p>To confirm this verify your mobile number with OTP</p>
//                     <input
//                         type="text"
//                         placeholder="Enter Mobile Number"
//                         value={mobileNumber}
//                         onChange={(e) => setMobileNumber(e.target.value)}
//                     />
//                     <button onClick={sendOtp}>SEND OTP</button>

//                     {otpSent && (
//                         <div className="otp-verification">
//                             <p>Enter OTP Code sent to your mobile number</p>
//                             <div className="otp-input">
//                                 {[...Array(4)].map((_, index) => (
//                                     <input
//                                         key={index}
//                                         type="text"
//                                         maxLength="1"
//                                         value={otp[index] || ''}
//                                         onChange={(e) => {
//                                             const newOtp = otp.split('');
//                                             newOtp[index] = e.target.value;
//                                             setOtp(newOtp.join(''));
//                                         }}
//                                     />
//                                 ))}
//                             </div>
//                             <button onClick={verifyOtp}>Verify OTP</button>
//                         </div>
//                     )}

//                     {otpVerified && (
//                         <button onClick={deleteAccount} className="delete-btn">Delete your Account</button>
//                     )}

//                     {showPopup && (
//                         <div className="popup">
//                             <p>Your relevant data are removed</p>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SRpage;

import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from "./json/delete.json"; // Your Lottie animation file
import './DeleteAccount.css';
import { InputOTP } from 'antd-input-otp';
import { Container, Navbar, Form, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import img from "./images/sr.png";
const SRpage = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [otpTimer, setOtpTimer] = useState(30);
   
    useEffect(() => {
        let timer;
        if (otpSent && otpTimer > 0) {
          timer = setTimeout(() => {
            setOtpTimer(otpTimer - 1);
          }, 1000);
        }
        return () => clearTimeout(timer);
      }, [otpSent, otpTimer]);

    const sendOtp = () => {
        setOtpSent(true);
    };

    const verifyOtp = () => {
        if (otp.length === 4) {
            setOtpVerified(true);
        }
    };

    const deleteAccount = () => {
        if (otpVerified) {
            setShowPopup(true);
        }
    };

    return (
      <>
      <div className="bg-white hea d-flex col-lg-12">
      <div className="col-lg-12 row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
      <Navbar bg="white" expand="lg">
            <Container fluid>
              <div className="col-lg-12 row">
                <div className="col-lg-3 d-flex">
                  {/* <FontAwesomeIcon className="text-dark vouicon mb-3" icon={faBars} /> */}
                  <Navbar.Brand href="#">
                    <img
                      alt=""
                      src={img}
                      width="200"
                      height="55"
                      className="justify-content-start mt-1"
                      />
                    </Navbar.Brand>
                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-4 mt-4">
            
                  </div>
                  <div className="col-lg-3 d-flex justify-content-end">
             
                  </div>
                </div>
              </Container>
            </Navbar>
            </div>
            <div className="col-lg-1"></div>
      </div>
      </div>
        <div className="container d-flex col-lg-12">
            <div className="animation">
                <Lottie animationData={animationData} loop={true} />
            </div>
            <div className="form-container">
                <h1>Delete Account</h1>
                <p>We are sorry to hear this from you,</p>
                <p>Deleting your account will remove all of your information from our database. This cannot be undone.</p>
                <p>To confirm this verify your mobile number with OTP</p>
                <input
                    type="text"
                    placeholder="Enter Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                />
                <button onClick={sendOtp}>SEND OTP</button>

                {otpSent && (
                    <div className="otp-verification">
                        <p>Enter OTP Code sent to +880********42</p>
                        <div className="otp-input">
                            <InputOTP
                                length={4}
                                otpType="number"
                                autoFocus
                                value={otp.split('')}
                                onChange={(value) => setOtp(value.join(''))}
                            />
                        </div>
                        <div className="otp-timer mt-3">
                            <p>{otpTimer} sec</p>
                        </div>
                        <button onClick={() => { verifyOtp(); setOtpVerified(true); }}>Verify & Proceed</button>
                    </div>
                )}

                {otpVerified && (
                    <button className='bg-danger' onClick={deleteAccount}>Delete your Account</button>
                )}

                {showPopup && (
                    <div className="popup">
                        <p>Your relevant data are removed</p>
                    </div>
                )}
            </div>
        </div>
        </>
    );
};

export default SRpage;


