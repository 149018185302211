import React from 'react';
import './Dining.css';
import  { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import wall from "./images/wall3.avif";
import spalogo from "./images/spalogo.png";
import { faEnvelope, faGlobeEurope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
function Template() {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    const storedTemplateId = localStorage.getItem('selectedTemplate');
    if (storedTemplateId) {
      setSelectedTemplate(parseInt(storedTemplateId));
    }
  }, []);

  const handleEditTemplate = (templateId) => {
    setSelectedTemplate(templateId);
    localStorage.setItem('selectedTemplate', templateId);
    window.location.href="/Dining"
  };



  const img=localStorage.getItem("handleimage")
  return (
    <>

    
      <div className="container col-lg-12 row mt-3 " style={{ backgroundImage: `url(${wall})`,height: '100vh',}}>
        <div className="col-lg-1 col-md-1"></div>
        <div className="container col-lg-10 row">
        <div className="container col-lg-12 row">





<div className="col-lg-4 mt-3">
<div className="container-fluid_1 col-lg-12 col-md-10 col-sm-12 row justify-content-left">

<div className="design-container" style={{     backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>


</div>
</div>

<div className=" col-lg-12 row justify-content-between mt-3">
<div className='col-lg-4'></div>
<div className="col-lg-4">
<button className="btn btn-outline-info" onClick={() => handleEditTemplate(1)}>Edit Template</button>
</div>
<div className='col-lg-4'></div>
</div>
</div>





<div className="col-lg-4 mt-3">
<div className="container-fluid_1 col-lg-12 col-md-10 col-sm-12 row justify-content-left">

<div className="design-container" style={{backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
<div className="top-right">
<img src={spalogo} height={35} width={100} alt="logo" />
</div>
<div className="bottom-left d-flex col-12 col-md-12 col-sm-12 row">

   <div className="image-div col-2 col-md-2 col-sm-2">
<img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg" alt="profile" className="image" />
</div>
</div>
</div>
</div>

<div className=" col-lg-12 row justify-content-between mt-3">
<div className='col-lg-4'></div>
<div className="col-lg-4">
<button className="btn btn-outline-info" onClick={() => handleEditTemplate(2)}>Edit Template</button>
</div>
<div className='col-lg-4'></div>
</div>
</div>





<div className="col-lg-4 mt-3">
<div className="container-fluid_1 col-lg-12 col-md-10 col-sm-12 row justify-content-left">

<div className="design-container" style={{     backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>

<div className="bottom-left d-flex col-12 col-md-12 col-sm-12 row">

   <div className="image-div col-2 col-md-2 col-sm-2">
<img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg" alt="profile" className="image" />
</div>
</div>
</div>
</div>

<div className=" col-lg-12 row justify-content-between mt-3">
<div className='col-lg-4'></div>
<div className="col-lg-4">
<button className="btn btn-outline-info" onClick={() => handleEditTemplate(3)}>Edit Template</button>
</div>
<div className='col-lg-4'></div>
</div>
</div>



</div>
        <div className="container col-lg-12 row">

        <div className="col-lg-4 mt-3">
        <div className="container-fluid_1 col-lg-12 col-md-10 col-sm-12 row justify-content-left">
          {/* <div className="design-container" style={{ backgroundImage: `url("https://i.pinimg.com/474x/ef/0a/6b/ef0a6b5768d368f9fdd797100c65f211.jpg")`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}> */}
          {/* <div className="design-container" style={{ backgroundImage:`url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}> */}
          <div className="design-container" style={{ 
  backgroundImage: `url(${img})`, 
  backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' 


}}>
            <div className="top-right">
              <img src={spalogo} height={35} width={100} alt="logo" />
            </div>
            <div className="bottom-left d-flex col-12 col-md-12 col-sm-12 row">
              <div className="image-div col-2 col-md-2 col-sm-2">
                <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg" alt="profile" className="image" />
              </div>
              <div className="bottom-right col-10 col-md-10 col-sm-10">
                <div className="bottom-inside col-12 col-md-12 col-sm-12">
                  <h6 className="para p-3"><b>One Life Financial planners</b></h6>
                </div>
                <div className="bottom-address col-12 col-sm-12 col-md-12  d-flex">
              
                      <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faUser} />Stella</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faPhone} /> 9234567899</h6></div>
                  </div>
                  <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faEnvelope} />food@gmail.com</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faGlobeEurope} />trusted consultant</h6></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className=" col-lg-12 row justify-content-between mt-3">
                <div className='col-lg-4'></div>
                <div className="col-lg-4">
                  <button className="btn btn-outline-info" onClick={() => handleEditTemplate(4)}>Edit Template</button>
                </div>
                <div className='col-lg-4'></div>
              </div>
  
        </div>

        <div className="col-lg-4 mt-3">
        <div className="container-fluid_1 col-lg-12 col-md-10 col-sm-12 row justify-content-left">
         
          <div className="design-container" style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div className="top-right">
              <img src={spalogo} height={35} width={100} alt="logo" />
            </div>
            <div className="bottom-left d-flex col-12 col-md-12 col-sm-12 row">
              <div className="image-div col-2 col-md-2 col-sm-2">
                <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg" alt="profile" className="image" />
              </div>
              <div className="bottom-right  col-10 col-md-10 col-sm-10">
                <div className="bottom-inside1 col-12 col-md-12 col-sm-12">
                  <h6 className="para p-3"><b>One Life Financial planners</b></h6>
                </div>
                <div className="bottom-address1 col-12 col-sm-12 col-md-12 d-flex">
                <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faUser} />Stella</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faPhone} /> 9234567899</h6></div>
                  </div>
                  <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faEnvelope} />food@gmail.com</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faGlobeEurope} />trusted consultant</h6></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className=" col-lg-12 row justify-content-between mt-3">
            <div className='col-lg-4'></div>
            <div className="col-lg-4">
              <button className="btn btn-outline-info" onClick={() => handleEditTemplate(5)}>Edit Template</button>
            </div>
            <div className='col-lg-4'></div>
          </div>
  
        </div>





        <div className="col-lg-4 mt-3">
        <div className="container-fluid_1 col-lg-12 col-md-10 col-sm-12 row justify-content-left">
        
          <div className="design-container" style={{     backgroundImage: `url(${img})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div className="top-right">
              <img src={spalogo} height={35} width={100} alt="logo" />
            </div>
            <div className="bottom-left d-flex col-12 col-md-12 col-sm-12 row">
        
              <div className="bottom-right col-10 col-md-10 col-sm-10">
                <div className="bottom-inside2 col-12 col-md-12 col-sm-12">
                  <h6 className="para p-3"><b>One Life Financial planners</b></h6>
                </div>
                <div className="bottom-address2 col-12 col-sm-12 col-md-12 d-flex">
                  <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faUser} />Stella</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faPhone} /> 9234567899</h6></div>
                  </div>
                  <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faEnvelope} />food@gmail.com</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faGlobeEurope} />trusted consultant</h6></div>
                  </div>
                </div>
                
              </div>
                   <div className="image-div col-2 col-md-2 col-sm-2">
                <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg" alt="profile" className="image" />
              </div>
            </div>
          </div>
          </div>
   
        <div className=" col-lg-12 row justify-content-between mt-3">
            <div className='col-lg-4'></div>
            <div className="col-lg-4">
              <button className="btn btn-outline-info" onClick={() => handleEditTemplate(6)}>Edit Template</button>
            </div>
            <div className='col-lg-4'></div>
          </div>
        </div>



        </div>










        </div>










        
        <div className="col-lg-1 col-md-1"></div>
      </div>




















      
    </>
  );
}

export default Template;
