
import React, { useState, useRef,useEffect } from 'react';
import './Dining.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobeEurope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import wall from "./images/wall3.avif";
import { BASEURL } from './Baseurl/Baseurl';

function Custom() {
  const defaultState = {
    companyName: 'One Life Financial planners',
    contactName: 'Stella',
    phoneNumber: '9234567899',
    email: 'food@gmail.com',
    consultant: 'trusted consultant',
    backgroundImage: 'https://images.pexels.com/photos/1821095/pexels-photo-1821095.jpeg?auto=compress&cs=tinysrgb&w=800',
    logoImage: 'https://st2.depositphotos.com/4035913/6124/i/450/depositphotos_61243733-stock-illustration-business-company-logo.jpg',
    profileImage: 'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg',
    logoPosition: 'top-right',
  };

  const [companyName, setCompanyName] = useState(defaultState.companyName);
  const [contactName, setContactName] = useState(defaultState.contactName);
  const [phoneNumber, setPhoneNumber] = useState(defaultState.phoneNumber);
  const [email, setEmail] = useState(defaultState.email);
  const [consultant, setConsultant] = useState(defaultState.consultant);
  const [backgroundImage, setBackgroundImage] = useState(defaultState.backgroundImage);
  const [logoImage, setLogoImage] = useState(defaultState.logoImage);
  const [profileImage, setProfileImage] = useState(defaultState.profileImage);
  const [logoPosition, setLogoPosition] = useState(defaultState.logoPosition);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [marginRight, setMarginRight] = useState('0px');
  const componentRef = useRef(null);



  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setMarginRight('0px'); 
      } else if (window.innerWidth >= 768) {
        setMarginRight('60px'); 
      } else {
        setMarginRight('0px'); 
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageChange = (setState) => (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setState(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const handleDownloadAfterStyleApplied = async () => {
      if (downloadClicked) {
        try {
          const designContainer = componentRef.current.querySelector('.design-container');
          const imageUrl = await htmlToImage.toPng(designContainer, { width: 385, height: 500 });
          download(imageUrl, 'design.png');
        } catch (error) {
          console.error('Error generating design:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'An error occurred while generating the design. Please try again later.',
          });
        } finally {
          
          setMarginRight(() => {
            if (window.innerWidth >= 1200) {
              return '0px'; 
            } else if (window.innerWidth >= 768) {
              return '60px';
            } else {
              return '0px'; 
            }
          });
          setDownloadClicked(false);
        }
      }
    };
  
    if (downloadClicked) {
      handleDownloadAfterStyleApplied();
    }
  }, [downloadClicked]);
  
  const [imageSize, setImageSize] = useState('large');
  const imageRef = useRef(null);


  const handleDownload = async () => {
    const confirmResult = await Swal.fire({
      icon: 'question',
      title: 'Download Design',
      text: 'Are you sure you want to download the design?',
      showCancelButton: true,
      confirmButtonText: 'Yes, download it!',
      cancelButtonText: 'Cancel',
    });
  
    if (confirmResult.isConfirmed) {
      try {
        const sb_branding_downloads_usid = localStorage.getItem('userId');
        const sb_branding_downloads_caid = localStorage.getItem('selectedCategoryId');
        const sb_branding_downloads_suid = localStorage.getItem('selectedsubCategoryId');
        const sb_branding_downloads_crid = localStorage.getItem('crid');
        const sb_branding_downloads_img_id = localStorage.getItem('handleid');
        const sb_branding_downloads_paid = 1;
        const sb_branding_downloads_valid = 1;
  
        // Check if mandatory fields are present
        if (!sb_branding_downloads_usid || !sb_branding_downloads_caid || !sb_branding_downloads_suid || !sb_branding_downloads_crid) {
          Swal.fire({
            icon: 'error',
            title: 'Download Failed',
            text: 'Access denied. Please log in to continue.',
          });
          return;
        }
  
        const response = await fetch(`${BASEURL}download`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sb_branding_downloads_usid,
            sb_branding_downloads_caid,
            sb_branding_downloads_suid,
            sb_branding_downloads_crid,
            sb_branding_downloads_img_id,
            sb_branding_downloads_paid,
            sb_branding_downloads_valid,
          }),
        });
  
        const result = await response.json();
        if (result.Response.Success === '1') {
          Swal.fire({
            icon: 'success',
            title: 'Download Successful',
            text: result.Response.Message,
          });
  
          if (window.innerWidth >= 1200) {
            setMarginRight('120px');
          } else if (window.innerWidth >= 768) {
            setMarginRight('180px');
          } else {
            setMarginRight('0px');
          }
  
          setDownloadClicked(true);
          setImageSize('small');
  
  
          if (imageRef.current) {
            imageRef.current.classList.add('image-70');
            console.log('CSS class "image-70" added to the image');
          } else {
            console.log('imageRef.current is null');
          }
        } else {
          if (result.Response.Error === 'Missing mandatory fields') {
            Swal.fire({
              icon: 'error',
              title: 'Download Failed',
              text: 'Access denied. Please log in to continue.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Download Failed',
              text: 'Access denied. Please log in to continue.',
            });
          }
          return;
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Download Failed',
          text: 'An error occurred. Please try again later.',
        });
        console.error('Error during download process:', error);
        return;
      }
    }
  };

  // const handleDownload = async () => {
  //   const confirmResult = await Swal.fire({
  //     icon: 'question',
  //     title: 'Download Design',
  //     text: 'Are you sure you want to download the design?',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, download it!',
  //     cancelButtonText: 'Cancel',
  //   });
  
  //   if (confirmResult.isConfirmed) {
     
  //     if (window.innerWidth >= 1200) {
  //       setMarginRight('60px');
  //     } else if (window.innerWidth >= 768) {
  //       setMarginRight('180px'); 
  //     } else {
  //       setMarginRight('0px'); 
  //     }
  //     setDownloadClicked(true);
  //   }
  // };
  
  

  return (
    <>


<div className='container col-lg-12 row'>
    <div className=' col-lg-1 '></div>
    <div className='car col-lg-10 mt-3' style={{ backgroundImage: `url(${wall})`,height: '100%',}}>
      <div className="container col-lg-12 row mt-3" ref={componentRef}>
        <div className="col-lg-4 col-md-2"></div>
        <div className="container-fluid_1 col-lg-4 col-md-10 col-sm-12 row">
          <div className="design-container" style={{ backgroundImage: `url("${backgroundImage}")`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            {logoPosition === 'top-left' ? (
              <div className="top-left ">
                <img src={logoImage} height={35} width={100} alt="logo" />
              </div>
            ) : (
              <div className="top-right" >
                <img src={logoImage} height={35} width={100} alt="logo" />
              </div>
            )}

            <div className="bottom-left d-flex col-12 col-md-12 col-sm-12 row">
              <div className="image-div col-2 col-md-2 col-sm-2">
                <img src={profileImage} alt="profile" className="image"ref={imageRef} />
              </div>
              <div className="bottom-right col-10 col-md-10 col-sm-10">
                <div className="bottom-inside col-12 col-md-12 col-sm-12">
                  <h6 className="para p-3"><b>{companyName}</b></h6>
                </div>
                <div className="bottom-address col-12 col-sm-12 col-md-12 d-flex">
                  <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faUser} /> {contactName}</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faPhone} /> {phoneNumber}</h6></div>
                  </div>
                  <div className="bottom-add1 col-6 col-md-6 col-sm-6 mt-1">
                    <div className="bottom-add"><h6 className="para"><FontAwesomeIcon icon={faEnvelope} /> {email}</h6></div>
                    <div className="bottom-add mt-2"><h6 className="para"><FontAwesomeIcon icon={faGlobeEurope} /> {consultant}</h6></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-2"></div>
      </div>

      <div className=" col-lg-12 row justify-content-between mt-5">
        <div className='col-lg-1'></div>
        <div className="col-lg-3 card">
          <div className="card-header">Background Image</div>
          <div className="card-body">
            <input type="file" accept="image/*" onChange={handleImageChange(setBackgroundImage)} />
          </div>
        </div>

        <div className="card col-lg-3">
          <div className="card-header">Logo Image</div>
          <div className="card-body">
            <input type="file" accept="image/*" onChange={handleImageChange(setLogoImage)} />
          </div>
          {/* <select value={logoPosition} onChange={(e) => setLogoPosition(e.target.value)} className='mb-4'>
            <option value="top-left">Top Left</option>
            <option value="top-right">Top Right</option>
          </select> */}
          <div className="card-footer">
                <select value={logoPosition} onChange={(e) => setLogoPosition(e.target.value)} className='form-select mb-4'>
                    <option value="top-left">Top Left</option>
                    <option value="top-right">Top Right</option>
                </select>
            </div>
        </div>

        <div className="card col-lg-3">
          <div className="card-header">Profile Image</div>
          <div className="card-body">
            <input type="file" accept="image/*" onChange={handleImageChange(setProfileImage)} />
          </div>
        </div>
        <div className='col-lg-1'></div>
      </div>

      <div className=" col-lg-12 row justify-content-between mt-2">
        <div className='col-lg-1'></div>
        <div className="card col-lg-10">
          <div className="card-header">Company Details</div>
          <div className="card-body row justify-content-between">
            <input type="text" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
            <input type="text" placeholder="Contact Name" value={contactName} onChange={(e) => setContactName(e.target.value)} />
            <input type="text" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
            <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder="Consultant" value={consultant} onChange={(e) => setConsultant(e.target.value)} />
            <button className="bg-primary boot" onClick={handleDownload}>Download Image</button>
          </div>
        </div>
        <div className='col-lg-1'></div>
      </div>
      </div>
      </div>
      <style>
        {`
          .top-right {
            margin-right: ${marginRight}; 
          }
        `}
      </style>
   
    </>
  );
}

export default Custom;