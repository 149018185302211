// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {  Modal } from 'react-bootstrap';
// import "./CSS_FILES/main.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight,faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
// import img1 from "./images/int.jpeg";
// import img2 from "./images/dec.jpeg";
// import img3 from "./images/makeup.jpeg";
// import img4 from "./images/stage.jpeg";
// import img5 from "./images/band.jpeg";
// import img6 from "./images/spa.jpeg";
// import img7 from "./images/parlour.jpeg";
// import img8 from "./images/saloon.jpeg";
// import img9 from "./images/re.jpeg";
// import img10 from "./images/cat.jpeg";
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// import wall from "./images/wall3.avif";
// export default function Dashboard() {
//   const [showModal, setShowModal] = useState(false);

 
//   const [title, setTitle] = useState('');
//   const [image, setImage] = useState(null);


//   const handleTitleChange = (e) => {
//     setTitle(e.target.value);
//   };

  
//   const handleImageChange = (e) => {
//     setImage(e.target.files[0]);
//   };


//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

  
//   const handleOpenModal = () => {
//     setShowModal(true);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     console.log("Submitted:", title, image);

//     setTitle('');
//     setImage(null);

//     handleCloseModal();
//   };

//   return (
//     <>
//       <div className="container-fluid mt-3" >
//         <div className="col-lg-12">
       
//           <div className="row">

//           <div className="col-lg-1"></div>
//           <div className="col-lg-10 ">
//           <div className="col-lg-12 justify-content-between">
//           <div className="row justify-content-between">
    
//      <div className="col-lg-6">
//   <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
//     <div className="carousel-inner">
//       <div className="carousel-item active">
//         <img alt="" src={"https://images.pexels.com/photos/1654760/pexels-photo-1654760.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover"  style={{  height: '250px' }}/>
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/9775025/pexels-photo-9775025.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover"  style={{  height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/10543812/pexels-photo-10543812.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover"  style={{  height: '250px' }}/>
//       </div>
//     </div>
//     <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
//       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//       <span className="sr-only">Previous</span>
//     </a>
//     <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
//       <span className="carousel-control-next-icon" aria-hidden="true"></span>
//       <span className="sr-only">Next</span>
//     </a>
//   </div>
// </div>

  


//           <div className="col-lg-6">
//           <div className="col-lg-12 justify-content-between">
//           <div className="row justify-content-between">



// <div className="col-lg-3 newdiv">
//   <div id="carouselExampleControls1" className="carousel slide" data-ride="carousel">
//     <div className="carousel-inner">
//       <div className="carousel-item active">
//         <img alt="" src={"https://images.pexels.com/photos/19949622/pexels-photo-19949622/free-photo-of-woman-in-wedding-dress-and-veil-smiling.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/15181108/pexels-photo-15181108/free-photo-of-woman-in-traditional-bridal-saree-dress.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/13946208/pexels-photo-13946208.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//     </div>
//     <a className="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
//       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//       <span className="sr-only">Previous</span>
//     </a>
//     <a className="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
//       <span className="carousel-control-next-icon" aria-hidden="true"></span>
//       <span className="sr-only">Next</span>
//     </a>
//   </div>
// </div>
// <div className="col-lg-3 newdiv">
//   <div id="carouselExampleControls2" className="carousel slide" data-ride="carousel">
//     <div className="carousel-inner">
//       <div className="carousel-item active">
//         <img alt="" src={"https://images.pexels.com/photos/5038747/pexels-photo-5038747.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/20331054/pexels-photo-20331054/free-photo-of-young-woman-with-colorful-makeup-standing-between-silver-and-pink-tinsel.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/169198/pexels-photo-169198.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//     </div>
//     <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
//       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//       <span className="sr-only">Previous</span>
//     </a>
//     <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
//       <span className="carousel-control-next-icon" aria-hidden="true"></span>
//       <span className="sr-only">Next</span>
//     </a>
//   </div>
// </div>
// <div className="col-lg-3 newdiv">
//   <div id="carouselExampleControls3" className="carousel slide" data-ride="carousel">
//     <div className="carousel-inner">
//       <div className="carousel-item active">
//         <img alt="" src={"https://images.pexels.com/photos/6621224/pexels-photo-6621224.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/2144413/pexels-photo-2144413.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/6543552/pexels-photo-6543552.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//     </div>
//     <a className="carousel-control-prev" href="#carouselExampleControls3" role="button" data-slide="prev">
//       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//       <span className="sr-only">Previous</span>
//     </a>
//     <a className="carousel-control-next" href="#carouselExampleControls3" role="button" data-slide="next">
//       <span className="carousel-control-next-icon" aria-hidden="true"></span>
//       <span className="sr-only">Next</span>
//     </a>
//   </div>
// </div>
// <div className="col-lg-3 newdiv">
//   <div id="carouselExampleControls4" className="carousel slide" data-ride="carousel">
//     <div className="carousel-inner">
//       <div className="carousel-item active">
//         <img alt="" src={"https://images.pexels.com/photos/4553028/pexels-photo-4553028.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/5946056/pexels-photo-5946056.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//       <div className="carousel-item">
//         <img alt="" src={"https://images.pexels.com/photos/9702318/pexels-photo-9702318.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
//       </div>
//     </div>
//     <a className="carousel-control-prev" href="#carouselExampleControls4" role="button" data-slide="prev">
//       <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//       <span className="sr-only">Previous</span>
//     </a>
//     <a className="carousel-control-next" href="#carouselExampleControls4" role="button" data-slide="next">
//       <span className="carousel-control-next-icon" aria-hidden="true"></span>
//       <span className="sr-only">Next</span>
//     </a>
//   </div>
// </div>


//           </div>
//           </div>
//           </div>
//           </div>
//           </div>
//           </div> 
//           <div className="col-lg-1"></div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
//           <div className="col-lg-12 mt-3">
          
//           <div className="row justify-content-between">
//           <div className="col-lg-1"></div>  
      
//    <div className="col-lg-5">
// <b><FontAwesomeIcon icon={faArrowRight}/>Go Related Background Image!</b>
// <hr></hr>


// <div className="col-lg-12 corner">
// <div class="col-lg-12 d-flex">
// <div class="col-lg-4">
//     <h6 class="mt-3"><b>Event Planners</b></h6></div>
//     <div class="col-lg-4"></div>
//     <div class="col-lg-4">
//     <Link to="/Category"> <Button variant="outline-primary" className="float-end mt-2" style={{ width: '80px',height:'30px' }}><h6 className="jik mb-1">More <FontAwesomeIcon icon={faArrowRight} /></h6></Button></Link>
//     </div>
// </div>

//   <div className="row justify-content-between mb-4">
//   <div className="col-lg-1"></div>
//     <div className="col-lg-3 newdivv mt-2">
//       <Card style={{ height: '90px' }}>
//         <Card.Img variant="top" src={img3} className="img-covere" />
//         <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//           <Card.Title><center>MUA</center></Card.Title>
//         </Card.Body>
// <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//           <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//         </Card.Footer>
//       </Card>
//     </div>

//     <div className="col-lg-3 newdivv mt-2">
//       <Card style={{ height: '90px' }}>
//         <Card.Img variant="top" src={img4} className="img-covere" />
//         <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//           <Card.Title><center>Stage Decors</center></Card.Title>
//         </Card.Body>
//         <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//           <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//         </Card.Footer>
//       </Card>
//     </div>

//     <div className="col-lg-3 newdivv mt-2">
//       <Card style={{ height: '90px' }}>
//         <Card.Img variant="top" src={img5} className="img-covere" />
//         <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//           <Card.Title><center>Band</center></Card.Title>
//         </Card.Body>
//         <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//           <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//         </Card.Footer>
//       </Card>
//     </div>
//     <div className="col-lg-1"></div>
//   </div>

// </div>

//    <div className="col-lg-12 mt-3 corner">

//    {/* <h6 className="mt-3"><b>  Dining</b></h6> */}
//    <div class="col-lg-12 d-flex">
// <div class="col-lg-4">
//     <h6 class="mt-3"><b>Dining</b></h6></div>
//     <div class="col-lg-4"></div>
//     <div class="col-lg-4">
//     <Button variant="outline-primary" className="float-end mt-2" style={{ width: '80px',height:'30px' }}><h6 className="jik mb-1">More <FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//     </div>
// </div>
    
// <div className="row justify-content-between mb-4">
// <div className="col-lg-1"></div>
// <div className="col-lg-3 newdivv mt-2 ">

// <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={"https://images.pexels.com/photos/19986463/pexels-photo-19986463/free-photo-of-table-in-restaurant-hall.jpeg?auto=compress&cs=tinysrgb&w=800"}  className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//         <Card.Title><center>Hotel</center></Card.Title>


//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//     <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
// </div>
// <div className="col-lg-3 newdivv mt-2">

// <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={img10}  className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//         <Card.Title><center>Catering</center></Card.Title>


//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
// </div>
// <div className="col-lg-3 newdivv mt-2">

// <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={"https://images.pexels.com/photos/9393356/pexels-photo-9393356.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//       <Card.Title><center>Decors</center></Card.Title>
 

//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
// </div>
// <div className="col-lg-1"></div>




// </div>

// </div>
//           </div>
//           <div className="col-lg-5">
//           <b>Let's shop again!</b>
// <hr></hr>
//           <div className="col-lg-12 corner">
//           {/* <h6 className="mt-3"><b>  Saloon & spa</b></h6> */}
//           <div class="col-lg-12 d-flex">
// <div class="col-lg-4">
//     <h6 class="mt-3"><b> Saloon & spa</b></h6></div>
//     <div class="col-lg-4"></div>
//     <div class="col-lg-4">
//     <Button variant="outline-primary" className="float-end mt-2" style={{ width: '80px',height:'30px' }}><h6 className="jik mb-1">More <FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//     </div>
// </div>
//   <div className="row justify-content-between mb-4">
//   <div className="col-lg-1"></div>
//     <div className="col-lg-3 newdivv mt-2">
//     <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={img8} className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//       <Card.Title><center>Hair Salon</center></Card.Title>


//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
//     </div>
//     <div className="col-lg-3 newdivv mt-2">
//     <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={img6}  className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//         <Card.Title><center>Spa & Massage</center></Card.Title>
  
//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
//     </div>
//     <div className="col-lg-3 newdivv mt-2">
//     <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={img3}  className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//         <Card.Title><center>Beauty Salon</center></Card.Title>


//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
//     </div>
//     <div className="col-lg-1"></div>
//   </div>
// </div>
// <div className="col-lg-12 mt-3 corner">

// <div class="col-lg-12 d-flex">
// <div class="col-lg-4">
//     <h6 class="mt-3"><b>Real Estate</b></h6></div>
//     <div class="col-lg-4"></div>
//     <div class="col-lg-4">
//     <Button variant="outline-primary" className="float-end mt-2" style={{ width: '80px',height:'30px' }}><h6 className="jik mb-1">More <FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//     </div>
// </div>
//   <div className="row justify-content-between mb-4">
//   <div className="col-lg-1"></div>
//   <div className="col-lg-3 newdivv mt-2">
//     <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={img9} className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//       <Card.Title><center>Land</center></Card.Title>


//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
//     </div>
//     <div className="col-lg-3 newdivv mt-2">
//     <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={"https://images.pexels.com/photos/4307962/pexels-photo-4307962.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load"}  className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//         <Card.Title><center>Home</center></Card.Title>
      

//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
//     </div>
//     <div className="col-lg-3 newdivv mt-2">
//     <Card style={{ height: '90px' }}>
//       <Card.Img variant="top" src={"https://images.pexels.com/photos/4245961/pexels-photo-4245961.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load"}  className="img-covere"/>
//       <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//         <Card.Title><center>Office</center></Card.Title>


//       </Card.Body>
//       <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
//       <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
//   </Card.Footer>
//     </Card>
//     </div>
//     <div className="col-lg-1"></div>
//   </div>
// </div>

//<div className="col-lg-12 mt-3">
//<Link to="/Category"><Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1">view all</h6></Button></Link>
//<hr></hr>
//</div>
// </div>
// <div className="col-lg-1"></div>  


// </div>
//           </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }






import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {  Modal } from 'react-bootstrap';
import "./CSS_FILES/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faPlus, faRocket, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import wall from "./images/wall3.avif";
import 'google-fonts';

// This will import and apply the Roboto font with weight 700 (bold)
import { cursive } from 'google-fonts';

import { BASEURL } from "./Baseurl/Baseurl";
import Tags from "./Tags";


export default function Dashboard() {
  const [showModal, setShowModal] = useState(false);
  // const [visitorCount, setVisitorCount] = useState(10000); 

  // useEffect(() => {
 
  //   setVisitorCount(prevCount => prevCount + 1);
  // }, []);
  const [visitorCount, setVisitorCount] = useState(0); // Initialize to 0 or a loading state

  useEffect(() => {
    fetch(`${BASEURL}increment-visitor-count`)
      .then(response => response.json())
      .then(data => {
        if (data.Response && data.Response.Success === "1") {
          setVisitorCount(data.Response.Result);
        } else {
          console.error('Error fetching visitor count:', data.Response.Error);
        }
      })
      .catch(error => console.error("Error fetching visitor count:", error));
  }, []);
 
  const splitDigits = (number) => {
    return number.toString().split('').map((digit, index) => (
      <input 
        key={index} 
        className="digit-box" 
        value={digit} 
        readOnly
      />
    ));
  };
  
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);


  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submitted:", title, image);

    setTitle('');
    setImage(null);

    handleCloseModal();
  };
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch(`${BASEURL}categories_with_subcategories`)
      .then(response => response.json())
      .then(data => {
        setCategories(data.Response.Result);
      })
      .catch(error => console.error("Error fetching categories:", error));
  }, []);

  const handleButtonClick = (categoryId) => {
    localStorage.setItem('selectedCategoryId', categoryId);
window.location.href="/Category"
  }
  


  
  return (
    <>
      <div className="container-fluid mt-3" >
        <div className="col-lg-12">
       
          <div className="row">

          <div className="col-lg-1"></div>
          <div className="col-lg-10 ">
          <div className="col-lg-12 justify-content-between">
          <div className="row justify-content-between">
    
     <div className="col-lg-6">
  <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img alt="" src={"https://images.pexels.com/photos/1654760/pexels-photo-1654760.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover"  style={{  height: '250px' }}/>
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/9775025/pexels-photo-9775025.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover"  style={{  height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/10543812/pexels-photo-10543812.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover"  style={{  height: '250px' }}/>
      </div>
    </div>
    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a>
  </div>
</div>

  


          <div className="col-lg-6">
          <div className="col-lg-12 justify-content-between">
          <div className="row justify-content-between">



<div className="col-lg-3 newdiv">
  <div id="carouselExampleControls1" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img alt="" src={"https://images.pexels.com/photos/19949622/pexels-photo-19949622/free-photo-of-woman-in-wedding-dress-and-veil-smiling.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/15181108/pexels-photo-15181108/free-photo-of-woman-in-traditional-bridal-saree-dress.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/13946208/pexels-photo-13946208.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
    </div>
    <a className="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a>
  </div>
</div>
<div className="col-lg-3 newdiv">
  <div id="carouselExampleControls2" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img alt="" src={"https://images.pexels.com/photos/5038747/pexels-photo-5038747.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/20331054/pexels-photo-20331054/free-photo-of-young-woman-with-colorful-makeup-standing-between-silver-and-pink-tinsel.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/169198/pexels-photo-169198.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
    </div>
    <a className="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a>
  </div>
</div>
<div className="col-lg-3 newdiv">
  <div id="carouselExampleControls3" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img alt="" src={"https://images.pexels.com/photos/6621224/pexels-photo-6621224.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/2144413/pexels-photo-2144413.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/6543552/pexels-photo-6543552.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
    </div>
    <a className="carousel-control-prev" href="#carouselExampleControls3" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carouselExampleControls3" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a>
  </div>
</div>
<div className="col-lg-3 newdiv">
  <div id="carouselExampleControls4" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <img alt="" src={"https://images.pexels.com/photos/4553028/pexels-photo-4553028.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/5946056/pexels-photo-5946056.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
      <div className="carousel-item">
        <img alt="" src={"https://images.pexels.com/photos/9702318/pexels-photo-9702318.jpeg?auto=compress&cs=tinysrgb&w=800"} className="img-cover" style={{ height: '250px' }} />
      </div>
    </div>
    <a className="carousel-control-prev" href="#carouselExampleControls4" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carouselExampleControls4" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a>
  </div>
</div>


          </div>
          </div>
          </div>
          </div>
          </div>
          </div> 
          <div className="col-lg-1"></div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
          <div className="col-lg-12 mt-3">
          
          <div className="row justify-content-between">
          <div className="col-lg-1"></div>  
      
   <div className="col-lg-10">
   <div className="col-lg-12 d-flex">
   {/* <div className="col-lg-9 d-flex">
<b><FontAwesomeIcon icon={faArrowRight}/><h4> Fuel your creative journey with these free tools!</h4> </b>
</div> */}
    <div className="col-lg-9 d-flex">
      <span className="custom-text">
        <FontAwesomeIcon icon={faRocket}/>  Fuel your creative journey with these free tools!
       
      </span>
    
      <button className="cta ml-3">
      <Link to="/Tags">
  <span class="hover-underline-animation"> Search now </span></Link>
  <svg
    id="arrow-horizontal"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="10"
    viewBox="0 0 46 16"
  >
    <path
      id="Path_10"
      data-name="Path 10"
      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
      transform="translate(30)"
    ></path>
  </svg>
</button>

    </div>

{/* <b className="float-end">Visitors Count: {visitorCount}</b> */}






<div className="col-lg-3 float-end">
  {/* <Card className="cardn">
    <Card.Body>
      <Card.Title className="ffont mt-2">Website Guests</Card.Title>
      <div className="digit-box-container">
         {splitDigits(visitorCount)}
      </div>
    </Card.Body>
  </Card> */}
  <div class="notification">
    <div class="notiglow"></div>
    <div class="notiborderglow"></div>
    <div class="notititle">Website Guests</div>
    <div className="notibody">
         {splitDigits(visitorCount)}
      </div>
    {/* <div class="notibody">Contribute to Open Source UI Elements</div> */}
  </div>

  </div>
                        </div>
{/* <hr className="hrcode"></hr> */}


<div className="col-lg-12 mt-3 row">
{categories.map(category => (
<div className="col-lg-6 corner custom-width mt-3"style={{ width: '80%'}} key={category.sb_category_id}>
<div class="col-lg-12 d-flex">
<div class="col-lg-4">
 <h6 class="mt-3"><b>{category.sb_category_title}</b></h6></div>
 <div class="col-lg-4"></div>

 {/* <div class="col-lg-4">
 <Button variant="outline-primary" className="float-end mt-2" style={{ width: '80px',height:'30px' }}><h6 className="jik mb-1">More <FontAwesomeIcon icon={faArrowRight} /></h6></Button>
 </div> */}
 <div class="col-lg-4">
  <Button
    variant="outline-primary"
    className="float-end mt-2"
    style={{ width: '80px', height: '30px' }}
    onClick={() => handleButtonClick(category.sb_category_id)}
  >
    <h6 className="jik mb-1">More <FontAwesomeIcon icon={faArrowRight} /></h6>
  </Button>
</div>


</div>
 
<div className="row justify-content-between align-items-center mt-4 p-2">
<div className="col-lg-1"></div>

{category.subcategories.map(subcategory => (
<div className="col-lg-3 mt-2 "  key={subcategory.sb_category_id}>

{/* <Card style={{ height: '90px' }}>
   <Card.Img variant="top" src={subcategory.sb_category_icon}   className="img-covere"/>
   <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
     <Card.Title><center>{subcategory.sb_category_title}</center></Card.Title>


   </Card.Body>
   <Card.Footer style={{ height: '60px', display: 'flex', justifyContent: 'center' }}>
 <Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1"><FontAwesomeIcon icon={faArrowRight} /></h6></Button>
</Card.Footer>
 </Card> */}
   <Card style={{ height: '175px', padding: '5%', textAlign: 'center' }} className="newwww">
                              {/* <Card.Img variant="top" src={subcategory.sb_category_icon} className="rounded" style={{ width: '185px', height: '100px'}} /> */}
                              <Card.Img
        variant="top"
        src={subcategory.sb_category_icon}
        className="rounded"
        style={{ width: '100%', height: '100px', objectFit: 'cover' }}
      />
                              <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Card.Title style={{fontSize:'15px'}}><center>{subcategory.sb_category_title}</center></Card.Title>

                              </Card.Body>
                            </Card>
</div>
 ))}

<div className="col-lg-1"></div>




</div>
</div>
))}
</div>
<div className="col-lg-12 d-flex mt-3">
<div className="col-lg-6"></div>
<div className="col-lg-6">
<Link to="/Category"><Button variant="outline-primary" style={{ width: '100%' }}><h6 className="jik mt-1">view all</h6></Button></Link>
<hr></hr>
</div>
</div>

                {/* <div className="col-lg-5">
                  <b>Let's shop again!</b>
                
                </div> */}
                <div className="col-lg-1"></div>
              </div>
              <div className="col-lg-1"></div>  
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
