import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import Login from "./components/Login";
import { Header } from "./components/Header";





import Dashboard from "./components/Dashboard";

import { Header2 } from "./components/Header2";
import Dining from "./components/Dining";
import BgImg from "./components/BgImg";
import Template from "./components/Template";
import { Category } from "./components/Category";
import { Header3 } from "./components/Header3";
import SRpage from "./components/SRpage";
import Custom from "./components/Custom";
import Tags from "./components/Tags";

function App() {
  return (
    <>
      {/* <BrowserRouter basename="/panel/sparkpik"> */}
      <BrowserRouter>

        <Routes>
          <Route path="/Login" element={<Login />} />

          <Route path="/Header" element={<Header />} />
          <Route path="/Header2" element={<Header2/>} />
          <Route path="/Header3" element={<Header3/>} />
{/* 
          <Route path="/Dashboard" element={[<Header />, <Dashboard />]} /> */}


<Route path="/" element={[<Header />,<Header2 />,<Dashboard />]} />
<Route path="/Dining" element={[<Header />,<Header2 />,<Dining />]} />
<Route path="/Category" element={[<Header />,<Header2 />,<Category />]} />

         
        <Route path ="/BgImg" element={[<Header />,<Header3 />,<BgImg/>]}/>
        <Route path ="/Template" element={[<Header />,<Header2 />,<Template/>]}/>
        <Route path ="/Custom" element={[<Header />,<Header2 />,<Custom/>]}/>
        <Route path ="/Tags" element={[<Header />,<Tags/>]}/>
        <Route path ="/SRpage" element={<SRpage/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
