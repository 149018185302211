


import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {  Form,Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEllipsisVertical,
  faPlus,
  faTimes,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { BASEURL } from "./Baseurl/Baseurl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function Header3() {

const role=localStorage.getItem("role")
const userId=localStorage.getItem("userId")

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [accountHolder, setAccountHolder] = useState('');
  const [branchName, setBranchName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [upiId, setUpiId] = useState('');
  const [passbookImg, setPassbookImg] = useState(null);
  const [panImg, setPanImg] = useState(null);

  const [selectedImageTags, setSelectedImageTags] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');


  const handleCloseModal = () => {
    setShowModal(false);   
  };


  const handleCloseModal1 = () => {
    setShowModal1(false);

  };




  const mobile = localStorage.getItem('mobile');
  



  const [bankAccountName, setBankAccountName] = useState('');
  const [bankBranchName, setBankBranchName] = useState('');

  const [accountNumber, setAccountNumber] = useState('');

  const [verified, setVerified] = useState('');

  const [status, setStatus] = useState('');
  const [adminStatus, setAdminStatus] = useState('');



  const handleOpenModal = async () => {
    try {
      const mobile = localStorage.getItem('mobile');
      if (!mobile) {
    
        setSnackbarMessage('Please log in to view this content.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
  
      const response = await axios.get(`${BASEURL}loginuser/${mobile}`);
      const data = response.data;
  
      if (data.Response.Success === "1") {
        const user = data.Response.Result;
  
        setBankAccountName(user.sb_users_bank_account_name || '');
        setBankBranchName(user.sb_users_bank_branch_name || '');
        setIfscCode(user.sb_users_bank_ifsc_code || '');
        setAccountNumber(user.sb_users_bank_account_number || '');
        setUpiId(user.sb_users_upi_id || '');
        setVerified(user.sb_users_verified || '');
        setStatus(user.sb_users_status || '');
        setAdminStatus(user.sb_users_admin_status || '');
  
        const profileImg = user.sb_users_profile_img || '';
        const panImage = user.sb_users_pan_img || '';
        const passbookImage = user.sb_users_passbook_img || '';
  
        setPanImg(panImage);
        setPassbookImg(passbookImage);
  
        setPreview(profileImg);
        setPreview1(panImage);
        setPreview2(passbookImage);
  
     
        if (role == "1") {
          setShowModal(true);
        } else {
          setShowModal1(true);
        }
      } else {
        // alert('Failed to retrieve user data.');
        setSnackbarMessage('Failed to retrieve user data.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // alert('Login Your Register Account First!');
      setSnackbarMessage('Sign in to access your account.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  



const [preview, setPreview] = useState(localStorage.getItem('profile') || '');
const [preview1, setPreview1] = useState('');
const [preview2, setPreview2] = useState('');

useEffect(() => {
  if (preview) {
    console.log("preview", preview);
  }
}, [preview]);

useEffect(() => {
  if (preview1) {
    console.log("preview1", preview1);
  }
}, [preview1]);

useEffect(() => {
  if (preview2) {
    console.log("preview2", preview2);
  }
}, [preview2]);


const handlePanImgChange = (event) => {
  const file = event.target.files[0];
  setPanImg(file);

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  }
};

const handlePassbookImgChange = (event) => {
  const file = event.target.files[0];
  setPassbookImg(file);

  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  }
};




  const handleAddCategory = async () => {
    const userId = localStorage.getItem('userId');
    const formData = new FormData();

 
    formData.append('sb_users_bank_account_name', bankAccountName);
    formData.append('sb_users_bank_branch_name', bankBranchName);
    formData.append('sb_users_bank_ifsc_code', ifscCode);
    formData.append('sb_users_bank_account_number', accountNumber);
    formData.append('sb_users_upi_id', upiId);
    formData.append('sb_users_verified', verified);
    formData.append('sb_users_role', 2);
    formData.append('sb_users_status', status);
    formData.append('sb_users_admin_status', adminStatus);

 
    if (panImg) formData.append('sb_users_pan_img', panImg);
    if (passbookImg) formData.append('sb_users_passbook_img', passbookImg);

    try {
      const response = await axios.post(`${BASEURL}update_user/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.Response.Success === "1") {
        // alert('User updated successfully!');
        // localStorage.setItem("role",2)
        // handleCloseProfileModal();
        // window.location.reload();
        setSnackbarMessage('User successfully updated!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        localStorage.setItem("role", 2);
        handleCloseProfileModal();
        window.location.reload();
    
      } else {
        // alert(response.data.Response.Result);
        setSnackbarMessage(response.data.Response.Result);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error updating user:', error);
      // alert('Failed to update user. Please try again.');
      setSnackbarMessage('Failed to update user. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseProfileModal = () => {
    setShowModal(false);

    
    setBankAccountName('');
    setBankBranchName('');
    setIfscCode('');
    setAccountNumber('');
    setUpiId('');
    setVerified('');
    // setRole('');
    setStatus('');
    setAdminStatus('');
    setPanImg('');
    setPassbookImg('');
  };








 
    // const [selectedFile, setSelectedFile] = useState(null);
  
    // const handleFileChange1 = (event) => {
    //   setSelectedFile(event.target.files[0]);
    // };
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const handleFileChange1 = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    const handleTagChange = (e) => {
      setSelectedImageTags(e.target.value);
    };
    const handleAddCategory1 = async () => {
      if (!selectedFile) {
        // alert('Please select a file');
        setSnackbarMessage('Please select a file');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
  
      const catId = localStorage.getItem('selectedCategoryId');
      const ScatId = localStorage.getItem('selectedsubCategoryId');
      const uId = localStorage.getItem('userId');
  
      if (!catId || !ScatId || !uId) {
        // alert('Missing required data');
        setSnackbarMessage('Missing required data');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
  
  
      const formData = new FormData();
      formData.append('img', selectedFile);
      formData.append('catId', catId);
      formData.append('ScatId', ScatId);
      formData.append('uId', uId);


      const tagsArray = selectedImageTags
    .split(',')
    .map(tag => tag.trim())
    .filter(tag => tag.length > 0);
  
  // Convert the array to a JSON string
  const tagsJSON = JSON.stringify(tagsArray);

  formData.append('tags', tagsJSON);

  console.log('FormData Tags:', formData.get('tags'));
  
      try {
        const response = await axios.post(`${BASEURL}branding/creator_image/add`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(response.data);
        alert(response.data.Response.Message);
        // window.location.reload()
        setSnackbarMessage(response.data.Response.Message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        // window.location.reload()
      } catch (error) {
        console.error('There was an error uploading the file!', error);
        setSnackbarMessage('There was an error uploading the file!');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <Navbar
              bg="light"
              expand="lg"
              style={{
                boxShadow: "0px 2px 5px rgba(0.3, 0, 0, 0.3)",
                backgroundColor: "#DEDEDE",
                borderBottom: "1.5px solid #000000",
                borderTop: "2px solid #D4D4D4",
              }}
            >
              <Container fluid>
                <div className="col-lg-12 row">
                  <div className="col-lg-9 d-flex">
                    <Link to="/">
                      <FontAwesomeIcon
                        className="homevou mt-3"
                        icon={faHome}
                      />
                    </Link>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                      <marquee behavior="scroll" direction="left" scrollamount="10">
                        <Nav className="me-auto ms-5 my-2 my-lg-0 font" style={{ maxHeight: "100px" }} navbarScroll>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Dining
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Electronics
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Fashion
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Wellness
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Gifting
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Home Needs
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            E-Commerce
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Entertainment
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Travel
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            MUA
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Salon & spa
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Decors
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Band
                          </Nav.Link>
                          <Nav.Link href="./" className="nav-link text-dark mt-2 dash-text">
                            Event Planners
                          </Nav.Link>
                        </Nav>
                      </marquee>
                    </Navbar.Collapse>
                  </div>
                  <div className="col-lg-3 d-flex justify-content-end">
                    <FontAwesomeIcon
                      className="text-dark  mt-3"
                      icon={faEllipsisVertical}
                    />
                    <h5 className="mt-1 ml-2 gifticon2"> |</h5>
                    <button
                      className="stavou ml-5 d-flex"
                      onClick={handleOpenModal}

                     
                    >
                      <FontAwesomeIcon
                        className="p-3 ml-2 gifticon"
                        icon={faPlus}
                      />
                      <h6 className="jik mt-2 ml-3">
                        <u
                          style={{
                            marginTop: "4px",
                            display: "inline-block",
                          }}
                        >
                          Add Your backgroundImage
                        </u>
                      </h6>
                    </button>
                  </div>
                </div>
              </Container>
            </Navbar>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>



      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title className="text-dark">Begin with creating your profile as a creator!</Modal.Title>
          <button className="bg-danger  border-danger close-button" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="accountHolder">Account Holder:</label>
              <input
                type="text"
                className="form-control"
                id="accountHolder"
      
                value={bankAccountName}
                onChange={(e) => setBankAccountName(e.target.value)}
                placeholder="Enter account holder's name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="branchName">Branch Name:</label>
              <input
                type="text"
                className="form-control"
                id="branchName"
             
                value={bankBranchName}
                onChange={(e) => setBankBranchName(e.target.value)}
                placeholder="Enter branch name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="accountNo">Account No:</label>
              <input
                type="text"
                className="form-control"
                id="accountNo"
     
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                placeholder="Enter account number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="ifscCode">IFSC Code:</label>
              <input
                type="text"
                className="form-control"
                id="ifscCode"
         
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                placeholder="Enter IFSC code"
              />
            </div>
            <div className="form-group">
              <label htmlFor="upiId">UPI ID:</label>
              <input
                type="text"
                className="form-control"
                id="upiId"
            
                value={upiId}
                onChange={(e) => setUpiId(e.target.value)}
                placeholder="Enter UPI ID"
              />
            </div>

<Form.Group controlId="formPanImg">
        <Form.Label>PAN Image</Form.Label>
        <Form.Control type="file" onChange={handlePanImgChange} />
        {preview1 && (
          <div>
            <img
              alt="Pan Preview"
              src={preview1}
              width="100"
              height="100"
              className="justify-content-start mt-2"
            />
          </div>
        )}
      </Form.Group>

      <Form.Group controlId="formPassbookImg">
        <Form.Label>Passbook Image</Form.Label>
        <Form.Control type="file" onChange={handlePassbookImgChange} />
        {preview2 && (
          <div>
            <img
              alt="Passbook Preview"
              src={preview2}
              width="100"
              height="100"
              className="justify-content-start mt-2"
            />
          </div>
        )}
      </Form.Group>
   
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={handleAddCategory}
            >
              Update 
            </button>
          </form>
        </Modal.Body>
      </Modal>



<Modal show={showModal1} onHide={handleCloseModal1}>
      <Modal.Header>
        <Modal.Title className="text-dark">Choose and add your image!</Modal.Title>
        <button className="bg-danger border-danger close-button" onClick={handleCloseModal1}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal.Header>
      <Modal.Body>
        <form>
        
             <div className="form-group">
            <label htmlFor="bgimg">Upload Your Bg Image:</label>
            <input
              type="file"
              className="form-control"
              id="bgimg"
              onChange={handleFileChange1}
            />
          </div>
          {previewUrl && (
            <div className="image-preview">
              <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            </div>
          )}
<div className="form-group">
            <label htmlFor="tags">Tags:</label>
            <input
              type="text"
              className="form-control"
              id="tags"
              value={selectedImageTags}
              onChange={handleTagChange}
              placeholder="Enter tags, separated by commas"
            />
          </div>



          <Button
            type="button"
            className="btn btn-primary mt-3"
            onClick={handleAddCategory1}
          >
            Add Background Image
          </Button>
        </form>
      </Modal.Body>
    </Modal>

    <Snackbar open={snackbarOpen} autoHideDuration={8000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}




