


// const BASEURL = 'https://sparkredeem.com/sparky/';


// const BASEURL = 'http://localhost:3009/';

const BASEURL ='https://sparkpik.com/piky/'

export { BASEURL };

