
// import React, { useEffect } from "react";
// import logo from "./images/logoimg.png";
// import "./CSS_FILES/main.css";
// import { Link } from "react-router-dom";
// import Lottie from 'lottie-react';
// import wall from "./images/wall.jpg";
// // Import your Lottie animation JSON file
// import animationData from "./json/ti.json";

// export default function Login() {
//   useEffect(() => {
//     if (localStorage.getItem("auth", true));
//   }, []);

//   const handleSubmit = (event) => {
//     localStorage.clear();
//     event.preventDefault();

//     const username = document.getElementById("username").value;
//     const password = document.getElementById("password").value;

//     if (username === "" || username === null) {
//       alert("Enter username");
//     } else if (password === "" || password === null) {
//       alert("Enter password");
//     } else {
//       const hardcodedEmail = "ADMIN";
//       const hardcodedPassword = "admin";

//       if (username === hardcodedEmail && password === hardcodedPassword) {
//         alert("Login successful");
//         window.location.href = "/Dashboard";
//       } else {
//         alert("Invalid login");
//       }
//     }
//   };

//   return (
//     <>
//         {/* <div className="container-fluid p-4" style={{ backgroundImage: `url(${wall})`,height:1000 }}> */}
//         <div className="hold-transition login-page" style={{ backgroundImage: `url(${wall})`, height: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>


//         <div className="login-box">
//           <div className="login-logo">
//             <img src={logo} alt="Logo" />
//           </div>
//           <div className="card" style={{ boxShadow: '0 0 10px skyblue' }}>


//             <div className="card-body login-card-body">
//               <h4 className="login-box-msg">Ticket Panel</h4>
            
//               <Lottie animationData={animationData} style={{ width: 320, height: 200 }} />


//               <form onSubmit={handleSubmit} className="mt-3">
//                 <div className="input-group mb-3 mt-3">
//                   <input
//                     type="text"
//                     name="username"
//                     id="username"
//                     className="form-control"
//                     placeholder="Username"
//                   />
//                   <div className="input-group-append">
//                     <div className="input-group-text">
//                       <span className="fas fa-envelope" />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="input-group mb-3">
//                   <input
//                     type="password"
//                     name="password"
//                     id="password"
//                     className="form-control"
//                     placeholder="Password"
//                   />
//                   <div className="input-group-append">
//                     <div className="input-group-text">
//                       <span className="fas fa-lock" />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-4"></div>

//                   <div className="col-4">
//                     <button
//                       type="submit"
//                       name="data_submit"
//                       id="data_submit"
//                       className="btn btn-primary btn-block"
//                     >
//                       Sign In
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* </div> */}

//     </>
//   );
// }
import React, { useEffect } from "react";
import logo from "./images/logoimg.png";
import "./CSS_FILES/main.css";
import { Link } from "react-router-dom";
import Lottie from 'lottie-react';
import wall from "./images/wall.jpg";

import animationData from "./json/lot.json";
import animationData1 from "./json/ti.json";
export default function Login() {
  useEffect(() => {
    if (localStorage.getItem("auth", true));
  }, []);

  const handleSubmit = (event) => {
    localStorage.clear();
    event.preventDefault();

    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;

    if (username === "" || username === null) {
      alert("Enter username");
    } else if (password === "" || password === null) {
      alert("Enter password");
    } else {
      const hardcodedEmail = "ADMIN";
      const hardcodedPassword = "admin";

      if (username === hardcodedEmail && password === hardcodedPassword) {
        alert("Login successful");
        window.location.href = "/Dashboard";
      } else {
        alert("Invalid login");
      }
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-lg-7 d-flex align-items-center" style={{ backgroundColor: '#800020' }}>
          <Lottie animationData={animationData1} style={{ width: '100%', height: '100vh' }} />
        </div>
        <div className="col-lg-5 d-flex align-items-center justify-content-center" style={{ backgroundColor: 'white' }}>
          <div className="login-box">
            <div className="login-logo">
              <img src={logo} alt="Logo" />
            </div>
            <div className="card" style={{ boxShadow: '0 0 10px #800020' }}>
              <div className="card-body login-card-body">
      
                <h4 className="login-box-msg"><b>Branding Panel</b></h4>
                <Lottie animationData={animationData} style={{ width: 320, height: 200 }} />
                <form onSubmit={handleSubmit} className="mt-3">
                  <div className="input-group mb-3 mt-3">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      className="form-control"
                      placeholder="Username"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                      <button
                        type="submit"
                        name="data_submit"
                        id="data_submit"
                        className="btn text-light btn-block"
                        style={{ backgroundColor: '#800020' }}
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
